
import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import {  makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Card,
  ButtonBase,
} from "@material-ui/core";
import pumpimg from './../../assets/img/PrimaryPump.png';
import SwitchSelector from "react-switch-selector";
import api from "../../api";
import SemiCircleProgressBar from "react-progressbar-semicircle";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:'0px 8px 40px #0123B433;',
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
     borderRadius: '12px',
     opacity:'1'
  },
  paper1:{
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow:'0px 0px 10px #0123B421',
    opacity:'1',
    borderRadius:'12px',
    height:'13vh',
    // display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControl: {
    autosize: true,
    clearable: false,
  }, 
  CardbodyInsideGrid: {
    "justify-content": "center",
    display: "inline-flex",
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
  },
  status:{
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    height: '100%' 
  },
  cardHeading:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    whiteSpace:"nowrap",
    color:"#000000",
    marginTop:'1vh',
    font:'normal normal medium 17px/60px Bw Seido Round',
    opacity:'1',
    fontWeight:'bold'
  },
  switchselector:{
    display:'flex',
    justifyContent:'center',
    width:"16vh",
    marginTop:'1vh',
  },
  semicircleBar:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    marginTop:'-0.8vh'
  },
  cardbody:{
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    fontSize:"4.5vh",
    fontWeight:'bold',
    opacity:'1',
    color:'#0123B4'
  }
}));
function GlPump(props) {
  const classes = useStyles();
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const [allPumpsData, setAllPumpsData] = useState([]);
  const [eachPumpsData, setEachPumpsData] = useState({});
  const [click, setClick] = useState("");
 
  const options1 = [
    {
      label: "Card View",
      value: "cardview",
      selectedBackgroundColor: "#0123b4",
    },
    {
      label: "Table View",
      value: "tableview",
      selectedBackgroundColor: "#0123b4",
    },
  ];
  const options = [
    {
      label: "OFF",
      value: "OFF",
      selectedBackgroundColor: "#0123B4",
      fontSize:"12"

    },
    {
      label: "ON",
      value: "ON",
      selectedBackgroundColor: "#34C759",
      fontSize:"12"
    },
  ];
  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === "ON"
  );
  const initialSelectedIndex1 = options1.findIndex(
    ({ value }) => value === "manual"
  );

  // const onClickIssue = () => {};
  useEffect(() => {
        if(props){
            console.log("propsssss",props)
                  if(props.location !== undefined){
                        console.log("propsssss in if",props.location.state)
                        if(props.location.state.click == 'heading'){
                              setClick('heading')
                              setAllPumpsData(props.location.state.data)
                        }
                  }else{
                        console.log("propsssss in else",props)
                              setClick('eachPump')
                              setEachPumpsData(props.data)
                  }
        }
  }, []);

  const onChange = (newValue) => {
  };
  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
        <Grid container spacing={2}>
            {/* <div style={{"overflow-x":"hidden","overflow-y": "scroll",scrollBehavior:"smooth",height:"82vh",marginTop:"1vh"}}> */}
            <Grid container spacing={2}>
                  { 
                  click == 'heading' ? <>
                  { allPumpsData.map((response) =>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                        <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>{response.name}</div></Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                            <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh'}}>
                                                      <SwitchSelector
                                                            style={{ borderRadius: "12px" }}
                                                            onChange={onChange}
                                                            options={options}
                                                            initialSelectedIndex={initialSelectedIndex}
                                                            // border="1px solid #0123B4"
                                                            backgroundColor={"#e9e5e5"}
                                                            fontColor={"rgba(0, 0, 0, 0.87)"}
                                                            // wrapperBorderRadius={true}
                                                            optionBorderRadius={5}
                                                            wrapperBorderRadius={5} />
                                                            </div>
                                                      </Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#68DBFF', color:'#0123B4', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                      <div>OFF</div>
                                                      </Grid>
                                                </Grid>
                                                </Card>
                                    </Grid>
                              </Grid>
                              </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                        Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                        </Card>
                        </Grid>
                   )}
                   </>
                  :         
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>{eachPumpsData.name}</div></Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                            <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh'}}>
                                                      <SwitchSelector
                                                            style={{ borderRadius: "12px" }}
                                                            onChange={onChange}
                                                            options={options}
                                                            initialSelectedIndex={initialSelectedIndex}
                                                            // border="1px solid #0123B4"
                                                            backgroundColor={"#e9e5e5"}
                                                            fontColor={"rgba(0, 0, 0, 0.87)"}
                                                            // wrapperBorderRadius={true}
                                                            optionBorderRadius={5}
                                                            wrapperBorderRadius={5} />
                                                            </div>
                                                      </Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                              <Grid container xs={12} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#FED0C1', color:'#FC6434', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                      <div>Fault</div>
                                                      </Grid>
                                                </Grid>
                                                </Card>
                                    </Grid>
                              </Grid>
                              </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                  Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                        </Card>
                   </Grid>
                  }
                
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>Jahn</div></Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{textDecorationLine:"underline",color:'#0123b4',fontWeight:'bold',fontSize:'2vh',whiteSpace:'nowrap'}}>3F-PH1-Pump-2</div></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                        <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh'}}>
                                                      <SwitchSelector
                                                          style={{ borderRadius: "12px" }}
                                                          onChange={onChange}
                                                          options={options}
                                                          initialSelectedIndex={initialSelectedIndex}
                                                          // border="1px solid #0123B4"
                                                          backgroundColor={"#e9e5e5"}
                                                          fontColor={"rgba(0, 0, 0, 0.87)"}
                                                          // wrapperBorderRadius={true}
                                                          optionBorderRadius={5}
                                                          wrapperBorderRadius={5} />
                                                        </div>
                                                      </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                              <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#68DBFF', color:'#0123B4', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                    <div>OFF</div>
                                                  </Grid>
                                                </Grid>
                                              </Card>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                   Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>Pump-3</div></Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{textDecorationLine:"underline",color:'#0123b4',fontWeight:'bold',fontSize:'2vh',whiteSpace:'nowrap'}}>3F-PH1-Pump-3</div></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                        <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh'}}>
                                                      <SwitchSelector
                                                          style={{ borderRadius: "12px" }}
                                                          onChange={onChange}
                                                          options={options}
                                                          initialSelectedIndex={initialSelectedIndex}
                                                          // border="1px solid #0123B4"
                                                          backgroundColor={"#e9e5e5"}
                                                          fontColor={"rgba(0, 0, 0, 0.87)"}
                                                          // wrapperBorderRadius={true}
                                                          optionBorderRadius={5}
                                                          wrapperBorderRadius={5} />
                                                        </div>
                                                      </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                              <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#C1EECD', color:'#34C759', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                    <div>ON</div>
                                                  </Grid>
                                                </Grid>
                                              </Card>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                   Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Card className={classes.paper} style={{height:"41.5vh"}}>
                        <Grid container xs={12} spacing={1} style={{marginTop:'0.5vh',marginLeft:'0.5vh'}}>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} direction="column">
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{color:'black',fontWeight:'bold',fontSize:'3vh',whiteSpace:'nowrap'}}>Pump-4</div></Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{textAlign:'left'}}><div style={{textDecorationLine:"underline",color:'#0123b4',fontWeight:'bold',fontSize:'2vh',whiteSpace:'nowrap'}}>3F-PH1-Pump-4</div></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                                        <div className={classes.switchselector} style={{height:'4vh',marginTop:'0.5vh'}}>
                                                      <SwitchSelector
                                                          style={{ borderRadius: "12px" }}
                                                          onChange={onChange}
                                                          options={options}
                                                          initialSelectedIndex={initialSelectedIndex}
                                                          // border="1px solid #0123B4"
                                                          backgroundColor={"#e9e5e5"}
                                                          fontColor={"rgba(0, 0, 0, 0.87)"}
                                                          // wrapperBorderRadius={true}
                                                          optionBorderRadius={5}
                                                          wrapperBorderRadius={5} />
                                                        </div>
                                                      </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <Grid container xs={12} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                              <Card  style={{ width: '94%', height: '100%',background:'#FED0C1 0% 0% no-repeat padding-box', opacity:'1',backgroundColor: '#C1EECD', color:'#34C759', textAlign: 'center', fontSize: '3vh', fontWeight: 'bold' }}>
                                                <Grid container xs={12} style={{ height: '100%' }}>
                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}  className={classes.status}>
                                                    <div>ON</div>
                                                  </Grid>
                                                </Grid>
                                              </Card>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={1} style={{marginTop:'1.5vh',marginLeft:'0.5vh'}}>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                    <Card className={classes.paper1} style={{display:'flex',height:'27vh'}}>
                                          <Grid container xs={12} spacing={1} direction="column">
                                          <Grid container item xs={12} justify="center" alignItems="center">
                                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <img src={pumpimg} />
                                          </Grid>
                                          </Grid>
                                          </Grid>
                                    </Card>
                              </Grid>
                              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                              <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Frequency                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.semicircleBar}>
                                                                  <SemiCircleProgressBar strokeWidth={20} stroke="#0123B4" diameter={100} orientation="up" percentage={50}  />
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Run Hours                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        400
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={1} >
                                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}>
                                                                  Flow GPM                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody}>
                                                                        1350
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading} style={{whiteSpace:'normal'}}>
                                                                   Inlet pressure                         
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0 kPa
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                                                <Card className={classes.paper1}>
                                                      <Grid container xs={12} spacing={1}>                       
                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.cardHeading}  style={{whiteSpace:'normal'}}>
                                                                  Outlet pressure                     
                                                                  </Grid>
                                                            </Grid>

                                                            <Grid container item xs={12}>
                                                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                  <div className={classes.cardbody} style={{marginTop:'-2vh'}}>
                                                                        0
                                                                  </div>
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </Card>
                                          </Grid>
                                    </Grid>
                              </Grid>
                        </Grid>
                    </Card>
                </Grid> */}
            </Grid>
            {/* </div> */}
        </Grid>
    </div>
  );
}

export default GlPump;

