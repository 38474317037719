import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Map, ImageOverlay, Marker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../assets/css/leaflet.css";
import { Box } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import SwitchSelector from "react-switch-selector";
import CardFooter from "components/Card/CardFooter";
import { CalendarToday } from "@material-ui/icons";
import { ButtonBase } from "@material-ui/core";
import { TextField, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import api from "../../api";
import { useSelector } from "react-redux";
// import TimeSeries from "../TimeSeries1234.js";
import TimeSeriescsu from "../TimeSeriescsu.js";


const Leaflet = require("leaflet");
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    autosize: true,
    clearable: false,
  },
  setptbutton: {
    minWidth: "8vh",
    borderRadius: "8px",
    height: "3vh",
    marginTop: "1vh",
    marginLeft: "-4vh",
  },
  control1: {
    width: "6vh",
    marginTop: "0vh",
    marginLeft: "-11vh",
  },
  text: {
    fontSize: "14px",
    color: " #292929",
  },
}));
const iconDevice1 = new Leaflet.Icon({
  iconUrl: require("../../assets/img/sensor-icon.png"),
  iconSize: new Leaflet.Point(0, 0),
  className: "leaflet-div-icon-2",
});

const state1 = {
  options: {
    chart: {
      id: "basic-bar",
      color: "green",
      events: {
        dataPointSelection: function (event, chartContext, config) {
        },
      },
    },
    xaxis: {
      categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    },
    stroke: {
      show: true,
      strokeWidth: 2,
      strokeDashArray: 2,
      strokeColor: "green",
    },
  },

  series: [
    {
      name: "series-1",
      data: [20, 30, 25, 30, 55, 40, 12, 22, 34, 32],
    },
  ],
};
const dataSeries = [
  [
    {
      date: "2014-04-29",
      value: 79470783
    },
    {
      date: "2014-04-30",
      value: 80170783
    }
  ],
  [
    {
      date: "2014-01-01",
      value: 150000000
    },
    {
      date: "2014-01-02",
      value: 160379978
    },
    {
      date: "2014-01-03",
      value: 170493749
    },
    {
      date: "2014-01-04",
      value: 160785250
    },
    {
      date: "2014-01-05",
      value: 167391904
    },
    {
      date: "2014-01-06",
      value: 161576838
    },
    {
      date: "2014-01-07",
      value: 161413854
    },
    {
      date: "2014-01-08",
      value: 152177211
    },
    {
      date: "2014-01-09",
      value: 140762210
    },
    {
      date: "2014-01-10",
      value: 144381072
    },
    {
      date: "2014-01-11",
      value: 154352310
    },
    {
      date: "2014-01-12",
      value: 165531790
    }

  ]
];
function Glcsu(props) {
  const role_id = localStorage.getItem("roleID")
  const classes = useStyles();
  const [floor, setFloor] = useState([]);
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const [ahudevice, setAhudevice] = useState([]);
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const [returnairtemperature, setReturnairtemperature] = useState([]);
  const [relativehumidity, setRelativehumidity] = useState([]);
  const[csuonoff,setCsuonoff]=useState([]);
  const options = [
    {
      selectedFontColor: "white",
      label: "OFF",
      value: 0,
      selectedBackgroundColor: "red",
    },
    {
      selectedFontColor: "white",
      label: "ON",
      value: 1,
      selectedBackgroundColor: "green",
    },
  ];
  const handleLocationClick = (name) => {
    props.history.push(`/admin/Glschedule`);
  };
  const handlefloorchange = (name, id) => {
    setFdata(name);
    api.floor.devicemap(id, "AHU").then((res) => {
      setAhudevice(res);

    })
  };

  useEffect(() => {
   
    api.dashboard.getMetricData(buildingID).then((res) => {
      res.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
      setFloor(res);
    
    })
   
    api.floor.csuGraphData("8c3dd20e-a6a7-11ed-9d07-9829a659c338").then((res) => {
      setReturnairtemperature(res.graphData[0]);
      setRelativehumidity(res.graphData[0])
      setCsuonoff(res.graphData[0])
    })

  })

  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={5}>
            {/* <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "max-content", minWidth: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Floor
              </InputLabel>
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Floor"
                value={fdata || ''}
              >
                {floor.map((_item) => (
                  <MenuItem
                    key={_item.id}
                    value={_item.name}
                    onClick={() => handlefloorchange(_item.name, _item.id)}
                  >
                    {_item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item xs={4}>
            {/* <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "max-content", minWidth: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Device
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Device"
              // value={data}
              >
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item xs={3}>
            <Paper
              className={classes.paper}
              style={{
                backgroundColor: " #0123b4",
                color: "white",
                borderRadius: "12px",
                height: "100%",
                textAlign: "left",
                fontWeight: "500",
              }}
            >
              <div
                style={{
                  marginTop: "12px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#ffffff",
                }}
              >
                Controls
              </div>
            </Paper>

          </Grid>

        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={9}>
            <Box
              boxShadow={3}
              className={classes.paper}
              style={{
                backgroundColor: "white",
                borderRadius: "14px",
                height: "56vh",
              }}
            >
              <Map
                //  ref={mapRef}
                attributionControl={false}
                doubleClickZoom={false}
                zoomControl={false}
                dragging={false}
                scrollWheelZoom={false}
                // className={"floor-map"}
                crs={Leaflet.CRS.Simple}
                center={[0, 0]}
                // bounds={[[0, 0], [950, 800]]}
                bounds={[
                  [0, 0],
                  [414, 843],
                ]}
                className={classes.bounds}
                style={{
                  width: "max-content",
                  minWidth: "100%",
                  height: "54vh",
                  backgroundColor: "white",
                }}
                onClick={(e) => {
                  // console.log(e)
                  console.log({ x: e.latlng.lat, y: e.latlng.lng });
                }}
              >
                <ImageOverlay
                  interactive
                  url={"https://localhost/CSU_GRAPHIC.png"}
                  // url={'/AHU_Graphic.png'}
                  // bounds={[[0, 0], [414, 670]]}
                  // bounds={[[0, 70], [405, 790]]}
                  bounds={[
                    [-10, 60],
                    [405, 760],
                  ]}
                />
                <Marker position={[204.921, 140]} icon={iconDevice1}>
                  <Tooltip direction="bottom" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Return Air Temperature
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
{"21"+ "℃"}
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[308.921, 448]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        Relative Humidity
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                          {/* 40% */}
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[99.9, 642]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Supply fan status
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        {"OFF"}
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[192.9, 651]} icon={iconDevice1}>
                  <Tooltip direction="top" opacity={1} permanent>
                    <div>
                      <span style={{ fontSize: "12px", fontWeight: "500" }}>
                        Supply Fan AM
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >

                      </div>
                    </div>
                  </Tooltip>
                </Marker>
                <Marker position={[170.59, 439.67]} icon={iconDevice1}>
                  <Tooltip direction="right" opacity={1} permanent>
                    <div>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          whiteSpace: "normal",
                        }}
                      >
                        Supply Fan Fault
                      </span>
                      <br />
                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "18px",
                          width: "59px",
                          color: " #ffffff",
                          fontWeight: "500",
                          fontSize: "15px",
                          "letter-spacing": "9px",
                        }}
                      >
                        {"-"}
                      </div>
                    </div>
                  </Tooltip>
                </Marker>
              </Map>


            </Box>
          </Grid>
          <Grid item xs={3}>
            <Grid container item spacing={1} direction="column">
              <Grid item xs={12}>
              {  
              role_id !=2 ? 
              <>
              {/* {not an admin} */}
                <Box
                  boxShadow={3}
                  className={classes.paper}
                  style={{
                    backgroundColor: " #ffffff",
                    borderRadius: "20px",
                    height: "49vh",
                  }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={6}>
                        <div
                          style={{
                            marginTop: "2vh",
                            marginLeft: "-5vh",
                            fontWeight: "300",
                            // marginBottom: "3px",
                            textDecoration: "none",
                            justifyContent: "space-evenly",
                            pointerEvents: "none", opacity: "0.4"
                          }}
                        >
                          <Typography className={classes.text}>
                            CSU ON/OFF
                          </Typography>
                          {/* <Typography
                            className={classes.text}
                            style={{ marginTop: "1vh", marginLeft: "-3vh" }}
                          >
                            P1
                          </Typography>
                          <Typography
                            className={classes.text}
                            style={{ marginTop: "1vh", marginLeft: "-3vh" }}
                          >
                            P2
                          </Typography> */}
                        </div>
                      </GridItem>
                      <GridItem xs={6}>
                        <div
                          style={{
                            marginTop: "2vh",
                            marginLeft: "5vh",
                            fontWeight: "300",
                            textDecoration: "none",
                            justifyContent: "space-evenly",
                            autosize: true,
                          }}
                        >
                          <div
                            className="your-required-wrapper"
                            style={{ minWidth: "14vh", marginLeft: "-7vh",pointerEvents: "none", opacity: "0.4" }}
                          >
                            <SwitchSelector
                              //   onChange={onChange}
                              options={options}
                              // initialSelectedIndex={initialSelectedIndex}
                              //   forcedSelectedIndex={onOff}
                              backgroundColor={"rgba(0, 0, 0, 0.04)"}
                              fontColor={"#000"}
                              selectedFontColor={"#000"}
                              border="5"
                              optionBorderRadius={0}
                              wrapperBorderRadius={0}
                              fontSize={12}
                            />
                          </div>
                          <Grid
                            container
                            item
                            direction="row"
                            justify="flex-start"
                          >
                            <Grid item xs={6}>
                              {/* <TextField
                                // label="%"
                                // placeholder={value8 + "℃"}
                                // style={{marginTop:'3px',marginLeft:'18px',"letter-spacing":"9px",width:'45px'}}
                                // name="set_point"
                                // autoComplete="off"
                                // value={setpt}
                                // onChange={handleChangeForsetpoint}
                                // className={classes.control1}
                                // variant="outlined"
                                // style={{ marginTop: '-1px' }}
                              /> */}
                            </Grid>
                            <Grid item xs={6}>
                              {/* <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="Blue"
                                // onClick={handleClick}
                                className={classes.setptbutton}
                              >
                                set
                              </Button> */}
                            </Grid>
                          </Grid>
                          <Grid container item direction="row">
                            <Grid item xs={6}>
                              {/* <TextField
                                // label="%"
                                // placeholder={value9 + "℃"}
                                // style={{marginTop:'3px',marginLeft:'15px',width:'45px'}}
                                // name="Sat_set_point"
                                // autoComplete="off"
                                // value={setSat}
                                // onChange={handleChangeForsetpointSAT}
                                // className={classes.control1}
                                // variant="outlined"
                                // style={{ marginTop: '-1px' }}
                              /> */}
                            </Grid>
                            <Grid item xs={6}>
                              {/* <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="Blue"
                                // onClick={handleClickSat}
                                className={classes.setptbutton}
                              >
                                set
                              </Button> */}
                            </Grid>
                          </Grid>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{ marginTop: "22vh", marginLeft: "-10vh" }}
                    >
                      <CalendarToday color="primary" />
                      <ButtonBase
                        style={{ color: "#3f51b5" ,pointerEvents: "none", opacity: "0.4"}}
                        onClick={() => handleLocationClick()}
                      >
                        Scheduler
                      </ButtonBase>
                    </Grid>
                  </CardFooter>
                </Box>
              </>
              :
              <>
              <Box
                  boxShadow={3}
                  className={classes.paper}
                  style={{
                    backgroundColor: " #ffffff",
                    borderRadius: "20px",
                    height: "49vh",
                  }}
                >
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={6}>
                        <div
                          style={{
                            marginTop: "2vh",
                            marginLeft: "-5vh",
                            fontWeight: "300",
                            // marginBottom: "3px",
                            textDecoration: "none",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Typography className={classes.text}>
                            CSU ON/OFF
                          </Typography>
                          {/* <Typography
                            className={classes.text}
                            style={{ marginTop: "1vh", marginLeft: "-3vh" }}
                          >
                            P1
                          </Typography>
                          <Typography
                            className={classes.text}
                            style={{ marginTop: "1vh", marginLeft: "-3vh" }}
                          >
                            P2
                          </Typography> */}
                        </div>
                      </GridItem>
                      <GridItem xs={6}>
                        <div
                          style={{
                            marginTop: "2vh",
                            marginLeft: "5vh",
                            fontWeight: "300",
                            textDecoration: "none",
                            justifyContent: "space-evenly",
                            autosize: true,
                          }}
                        >
                          <div
                            className="your-required-wrapper"
                            style={{ minWidth: "14vh", marginLeft: "-7vh" }}
                          >
                            <SwitchSelector
                              //   onChange={onChange}
                              options={options}
                              // initialSelectedIndex={initialSelectedIndex}
                              //   forcedSelectedIndex={onOff}
                              backgroundColor={"rgba(0, 0, 0, 0.04)"}
                              fontColor={"#000"}
                              selectedFontColor={"#000"}
                              border="5"
                              optionBorderRadius={0}
                              wrapperBorderRadius={0}
                              fontSize={12}
                            />
                          </div>
                          <Grid
                            container
                            item
                            direction="row"
                            justify="flex-start"
                          >
                            <Grid item xs={6}>
                              {/* <TextField
                                // label="%"
                                // placeholder={value8 + "℃"}
                                // style={{marginTop:'3px',marginLeft:'18px',"letter-spacing":"9px",width:'45px'}}
                                // name="set_point"
                                // autoComplete="off"
                                // value={setpt}
                                // onChange={handleChangeForsetpoint}
                                // className={classes.control1}
                                // variant="outlined"
                                // style={{ marginTop: '-1px' }}
                              /> */}
                            </Grid>
                            <Grid item xs={6}>
                              {/* <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="Blue"
                                // onClick={handleClick}
                                className={classes.setptbutton}
                              >
                                set
                              </Button> */}
                            </Grid>
                          </Grid>
                          <Grid container item direction="row">
                            <Grid item xs={6}>
                              {/* <TextField
                                // label="%"
                                // placeholder={value9 + "℃"}
                                // style={{marginTop:'3px',marginLeft:'15px',width:'45px'}}
                                // name="Sat_set_point"
                                // autoComplete="off"
                                // value={setSat}
                                // onChange={handleChangeForsetpointSAT}
                                // className={classes.control1}
                                // variant="outlined"
                                // style={{ marginTop: '-1px' }}
                              /> */}
                            </Grid>
                            <Grid item xs={6}>
                              {/* <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="Blue"
                                // onClick={handleClickSat}
                                className={classes.setptbutton}
                              >
                                set
                              </Button> */}
                            </Grid>
                          </Grid>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{ marginTop: "22vh", marginLeft: "-10vh" }}
                    >
                      <CalendarToday color="primary" />
                      <ButtonBase
                        style={{ color: "#3f51b5" }}
                        onClick={() => handleLocationClick()}
                      >
                        Scheduler
                      </ButtonBase>
                    </Grid>
                  </CardFooter>
                </Box>
              </>}
              </Grid>
              {/* <Grid item xs={12}>
                <Paper
                  className={classes.paper}
                  style={{
                    backgroundColor: " #0123b4",
                    borderRadius: "12px",
                    width: "max-content",
                    minWidth: "100%",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                  >
                    VAV
                  </div>
                </Paper>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={9}>
            <Grid container item spacing={1} direction="row">
              <Grid item xs={4}>
                <Box
                  boxShadow={3}
                  className={classes.paper}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "25vh",
                    alignContent: "center",
                  }}
                >
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} style={{ fontWeight: "500" }}>
                      Return Air Temperature
                    </Grid>
                    <Grid item xs={12}>
                      {Object.keys(returnairtemperature).map((key) => (
                        <>
                          {key === "Return Air Temperature" ? (
                            <TimeSeriescsu
                              style={{ width: "100%", height: "100%" }}
                              data={returnairtemperature[key]}
                              param={key}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  boxShadow={3}
                  className={classes.paper}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "25vh",
                    alignContent: "center",
                  }}
                >

                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} style={{ fontWeight: "500" }}>
                      Relative Humidity
                    </Grid>
                    <Grid item xs={12}>
                      {Object.keys(relativehumidity).map((key) => (
                        <>
                          {key === "relative_humidity" ? (
                            <TimeSeriescsu
                              style={{ width: "100%", height: "100%" }}
                              data={relativehumidity[key]}
                              param={key}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </Grid>
                  </Grid>

                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  boxShadow={3}
                  className={classes.paper}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "25vh",
                    alignContent: "center",
                  }}
                >
                   <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} style={{ fontWeight: "500" }}>
                      CSU ON/OFF
                    </Grid>
                    <Grid item xs={12}>
                      {Object.keys(csuonoff).map((key) => (
                        <>
                          {key === "CSU On/Off" ? (
                            <TimeSeriescsu
                              style={{ width: "100%", height: "100%" }}
                              data={csuonoff[key]}
                              param={key}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </Grid>
                  </Grid>


                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container xs={12} spacing={1} direction="row">
              <Grid item xs={12}>
                {/* <Box boxShadow={3} className={classes.paper} style={{height:"25vh"}}>1</Box> */}
                {/* <Box
                  boxShadow={3}
                  className={classes.paper}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "25vh",
                    alignContent: "center",
                  }}
                >
                  {/* <Chart
                    options={state1.options}
                    series={state1.series}
                    type="bar"
                    style={{ width: "100%", height: "100%" }}
                  /> */}
                {/* </Box>  */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  )
}

export default Glcsu;

