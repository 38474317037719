//import {React, useEffect, useState} from "react";
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import api from '../../api';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import moment from 'moment';
import { useSelector } from "react-redux";
// import { saveAs } from 'file-saver';
import bng from '../../assets/Images/bng.png';
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
// import { update } from 'plotly.js';
import { ButtonGroup,Button } from 'semantic-ui-react';
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = theme => ({
  loader: {
    size: "40px",
    color: "#26c6da"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
      marginTop:"-2vh",
      fontWeight: 'bold',
      borderRadius: '0.8vw',
      '&:focus': {
        backgroundColor: 'transparent', // Remove background color on focus
        boxShadow: 'none', // Remove shadow on focus
      },
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor:"#0123b4",borderRadius:"8px"
    },
    "& .MuiSelect-root ": {
      marginTop:"-2vh"
    },
    menuPaper: {
      boxShadow: 'none', // Remove shadow in the dropdown menu
    },
  },
});
const useStyles = makeStyles(styles);
// function getTableData(myinput = [], param_map = {}) {
//   var myoutput = [], odata = [];
//   var param_ids = {}, output_ids = {}, i = 0, j = 0;
//   var params = Object.keys(param_map);
//   for (i = 0; i < myinput.length; i++) {
//     odata = [];
//     for (j = 0; j < params.length; j++) {
//       odata[j] = myinput[i][params[j]];
//     }
//     myoutput.push(odata);
//   }

//   return myoutput;

// }


function getColumns(myheading = null,isDeviceReports = false) {
  // myheading =(myheading==null)? {'DeviceName':"DeviceName",'date':"date",'time':"time", 'Category':" Category", 'Key':"Key",'Value': "Value",'Unit': "Unit",'Set by': "Set by",'Criticality': "Criticality"}:myheading;
  myheading = (myheading === null) ? { 'DeviceName': "DeviceName", 'date': "Date", 'Time': "Time", 'Category': " Category", 'Key': "Key", 'Value': "Value", 'Unit': "Unit" } : myheading;
  if (isDeviceReports) {
    // Customize column definitions for Device Reports
    return [
      { 'field': 'Date', width: '100px', sortable: true, lockPosition: 'left',cellStyle: { 'border-right-color': '#e2e2e2' } },
      { 'field': 'DeviceName', width: '150px', sortable: true, lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } },
      { 'field': 'Parameter', width: '150px', sortable: true, lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } },
      { 'field': 'Time', width: '100px', sortable: true, 'filter': 'agNumberColumnFilter',lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } },
      { 'field': 'Description', width: '250px', sortable: true, lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } },

      // Add more columns as needed
    ];
  }
  let mykeys = Object.keys(myheading);
  // console.log("mykeys",mykeys)
  let columnDefs = [];
  for (let i = 0; i < mykeys.length; i++) {
    switch (mykeys[i]) {
      case 'DeviceName':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '150px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'Possible_causes':
        columnDefs.push({ 'field': mykeys[i],  'width': '400px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
      case 'Timestamp':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agDateColumnFilter', width: '100px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'Date':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agDateColumnFilter', width: '100px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'Time':
        columnDefs.push({ 'field': mykeys[i], 'filter': 'agDateColumnFilter', width: '100px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
        break;
      case 'measured_time': case "Acknowledged": case "Ignore_alarm": case 'Category': case 'Criticality': case 'triggered_time': case 'Ignore': case 'Modified_at': case 'ActiveTime_in_sec':
        // columnDefs.push({'field': mykeys[i], 'filter': 'agDateColumnFilter',width:'200px', 'sortable': true,cellStyle:{'border-right-color':'#e2e2e2'}});
        break;
      // case 'Category':
      //   columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '200px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
      //   break;
      // case 'username':
      //   columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '150px', 'sortable': true,lockPosition: 'left',cellStyle: { 'border-right-color': '#e2e2e2' } });
      //   break;
      // case 'Value':
      //   columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '100px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
      //   break;
      // case 'LoginDate':
      //     columnDefs.push({ 'field':'LoginDate', 'filter': 'agNumberColumnFilter', width: '100px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
      //   break;
      // case 'ActiveTime':
      //       columnDefs.push({ 'field': mykeys[i], 'filter': 'agNumberColumnFilter', width: '100px', 'sortable': true,lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
      //   break;

      default:
        columnDefs.push({ 'field': mykeys[i], 'filter': true, 'sortable': true, flex: '1',lockPosition: 'left', cellStyle: { 'border-right-color': '#e2e2e2' } });
    }

  }
  // console.log("columns",columnDefs)
  return columnDefs;
}
function prepareReportData(rData) {
  rData.map((r_d) => {
    let mes_time = r_d["measured_time"].split(' ');
    r_d.date = mes_time[0]
    r_d.time = mes_time[1]
    return r_d;
  })

  return { "Headings": getColumns(rData[0]), "RowData": rData }
}
function prepareUserReportData(rData) {
  rData.map((r_d) => {
    let mes_time = r_d["triggered_time"].split(' ');
    r_d.date = mes_time[0]
    r_d.time = mes_time[1]
  })

  return { "Headings": getColumns(rData[0]), "RowData": rData }
}
function prepareUserLoginReportData(rData) {

rData.map((r_d)=>{
  const time = new Date(0,0,0)
    time.setSeconds(r_d["ActiveTime_in_sec"])
     r_d.ActiveTime = ((time.getHours())+':'+(time.getMinutes())+':'+(time.getSeconds()))
  })
  return { "Headings": getColumns(rData[0]), "RowData": rData }
}

const staticData = [
  {
    DeviceName: 'Device 1',
    date: '2023-08-07',
    time: '12:00:00',
    Category: 'Category A',
    Key: 'Key 1',
    Value: 123,
    Unit: 'Units',
  },
  {
    DeviceName: 'Device 2',
    date: '2023-08-07',
    time: '13:30:00',
    Category: 'Category B',
    Key: 'Key 2',
    Value: 456,
    Unit: 'Units',
  },
  // ... add more static data objects ...
];
function GlEventsViewer() {
  let reportOptions = [
    // { "option": "ibmsevents", "title": "IBMS LOGS", "api": 'ibmsevents_table' },
    { "option": "UserLoginReport", "title": "User Login Report", "api": 'loginLogoutDetails' },
    { "option": "Device Reports", "title": "Device Reports" },
    { "option": "DeviceCritical", "title": "Device Critical Alarms", "api": 'alarmDeviceCritical' },
    // {"option":"DeviceTypeCritical","title":"DeviceType Critical","api":'alarmDeviceTypeCritical'},
    // { "option": "mostActiveAlarmCritical", "title": "Most Active Critical Alarms", "api": 'mostActiveAlarmCritical' },
    { "option": "restorecriticalalarms", "title": "Critical Alarms Restored", "api": 'criticalAlarmRestored' },
    { "option": "alarmDevice", "title": "Device NonCritical Alarms", "api": 'alarmDeviceNonCritical' },
    // {"option":"alarmDeviceType","title":"DeviceType Alarms","api":'alarmDeviceTypeNonCritical'},
    // { "option": "mostActiveAlarmNonCritical", "title": "Most Active NonCritical Alarms", "api": 'mostActiveAlarmNonCritical' },
    { "option": "restorenoncriticalalarms", "title": "Non-Critical Alarms Restored", "api": 'nonCriticalAlarmRestored' },
    // { "option": "TrendLogComparisionReport", "title": "Trend Log Comparision Report", "api": 'TrendLogComparisionReport' },
    // { "option": "scheduleActivityReport", "title": "AHU Schedule Activity Report", "api": 'scheduleActivityReport' },
    // { "option": "ActivitesperuserReport", "title": "Activites Per User Report", "api": 'ActivitesperuserReport' },
    // { "option": "ActivitesperserverReport", "title": "Activites Per Server Report", "api": 'ActivitesperserverReport' },
    { "option": "UserControlDetails", "title": "User Contol Details", "api": 'userDetails' },
    { "option": "AcknowledgedUser", "title": "Alarms Acknowledged By User", "api": 'acknowledgedUser' },
    { "option": "IgnoredUser", "title": "Alarms Ignored By User", "api": 'ignoredUser' },
  ]
  // const[reportOptions,setReportOptions]=useState(reports);
  // const [headings,setHeadings]=useState(getColumns());

  const classes = useStyles();
  const gridRef = useRef();
  // const [eventdata,setEventData]=React.useState([]);
  const [reportData, setReportData] = React.useState({ "Headings": getColumns(), "RowData": [] });
  // const [selectedRows, setSelectedRows] = useState();
  const [isloaded, setIsLoaded] = React.useState(true);
  const [stitle, setStitle] = useState('UserLoginReport');
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const [floor, setFloor] = useState([]);
  const [fid, setFId] = useState('');
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const [age, setAge] = React.useState('');
  const [age1, setAge1] = React.useState('');
  const [age3, setAge3] = React.useState('');
  const data = useSelector((state) => state.inDashboard.locationData);
  const [bid, setBId] = useState(localStorage.getItem("buildingID"));
  const [fname, setFName] = useState([]);
  const [dname, setDName] = useState([]);
  const [bdata, setBdata] = useState([]);
  const [ddata, setDData] = useState([]);
  const [bname, setBName] = useState([]);
 const [deviceid,setDeviceid]=useState();
 const [tableData, setTableData] = useState([]);
 const [week1,setWeek1] =useState([]);
 const [month1,setMonth1] =useState([]);
 const [month3,setMonth3] =useState([]);
 const [month6,setMonth6] =useState([]);
 const [year1,setYear1] =useState([]);
const [format,setFormat]=useState([]);
const [username,setUsername]=useState(localStorage.getItem("username"))


  useEffect(() => {

    let b_id = "",b_data = [];
      data.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
      data.filter((_each, i) => {
        if (_each.zone_type === "GL_LOCATION_TYPE_BUILDING") {
          b_data.push(_each);
        }
        b_data.map((res) => {
          b_id = res.uuid;
          setBId(res.uuid);
          setBName(res.name);
        });
        setBdata(b_data);
      });

      let f_data = [],temp="",
        sch_details = {};
      data.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
      data.filter((_each, i) => {
        if (
          _each.zone_type === "GL_LOCATION_TYPE_FLOOR" &&
          _each.zone_parent === b_id
        ) {
          f_data.push(_each);
          // if(_each.uuid == fid){
          //   temp=_each.uuid        
          // }
          setFName(_each.name);
            setFId(_each.uuid)
        }
      });
      setFdata(f_data);
      // if(temp ==""){
        setFName(f_data[0].name);
        temp = f_data[0].uuid
      // }
      let z_data = [],
        d_data = [];
      data.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
      data.filter((_each, i) => {
        if (
          _each.zone_type === "GL_LOCATION_TYPE_ZONE" &&
          _each.zone_parent === temp
        ) {
          z_data.push(_each);
          
          data.filter((eachObj) => {
            if (eachObj.zone_parent === _each.uuid) {
              d_data.push(eachObj);
            }
          });
          // setDeviceid(d_data[0].uuid)
          // setDName(d_data[0].name)
          // setDData(d_data)
  }});
 

    api.dashboard.getMetricData(buildingID).then((res) => {
      res.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
      setFloor(res);
    })

    
    // api['reports']['ibmsevents_table']().then(res => {
    //   setReportData(prepareReportData(res))
    // })
    api['reports']['login_login_details']().then(res => {
      setReportData(prepareUserLoginReportData(res))
    })
  }, [])
  const handleReportSelection = (myapi, option) => {
    
    setStitle(option)
    switch (option) {
      case 'DeviceCritical':
        api['reports']['critical_table']().then(res => {
          // setHeadings(getColumns(res[0]));
          //  setEventData(res);

          setReportData(prepareReportData(res));
        });
        break;

      case 'DeviceTypeCritical':
        api['reports']['Device_critical']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'mostActiveAlarmCritical':
        api['reports']['active_alarm']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'restorecriticalalarms':
        api['reports']['restore_critical_alarm']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'alarmDevice':
        // api['reports']['noncritical_table']().then(res => {
        //   console.log("res------------------------>>>>>>>>>>>>>>",res)
        //   const combinedData = res.map(alarm => {
        //     const possibleCauses = JSON.parse(alarm.possible_causes);
        //     console.log("possible_causes",possibleCauses)
        //     const noncriticalItems = possibleCauses.map(item => {
        //       const formattedItems = Object.entries(item).map(([key, value]) => `- ${key}: ${value}`);
        //       // console.log(formattedItems.join('\n'));
        //       return formattedItems.join('\n');
        //     });
            
        //     return {
        //       DeviceName: alarm.DeviceName,
        //       Parameter: alarm.Description,
        //       measured_time: alarm.measured_time,
        //       date: alarm.date,
        //       possible_causes: noncriticalItems.join('\n') // Join items with double newlines
        //     };
        //   });
        api['reports']['noncritical_table']().then(res => {
          console.log("res------------------------>>>>>>>>>>>>>>", res)
          const combinedData = res.map(alarm => {
            const possibleCauses = JSON.parse(alarm.possible_causes);
            console.log("possible_causes", possibleCauses)
            
            // Check if possibleCauses is not null before mapping
            const noncriticalItems = possibleCauses && possibleCauses.map(item => {
              const formattedItems = Object.entries(item).map(([key, value]) => `- ${key}: ${value}`);
              // console.log(formattedItems.join('\n'));
              return formattedItems.join('\n');
            });
        
            return {
              DeviceName: alarm.DeviceName,
              Parameter: alarm.Description,
              measured_time: alarm.measured_time,
              date: alarm.date,
              possible_causes: noncriticalItems ? noncriticalItems.join('\n') : 'null' // Join items with double newlines
            };
          });
          const updatedReportData = prepareReportData(combinedData);
          // console.log("updated=============", updatedReportData);
          setReportData(updatedReportData);
        });
      break;       
        
        
        
          
      case 'alarmDeviceType':
        api['reports']['device_nonCritical']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
        case 'mostActiveAlarmNonCritical':
          api['reports']['non_criticalalarm']().then(res => {
            const combinedData = res.map(alarm => {
              const possibleCauses = JSON.parse(alarm.possible_causes);
              const noncriticalItems = possibleCauses.map(item => {
                const formattedItems = Object.entries(item).map(([key, value]) => `- ${key}: ${value}`);
                // console.log(formattedItems.join('\n'));
                return formattedItems.join('\n');
              });
              
              return {
                DeviceName: alarm.DeviceName,
                Parameter: alarm.Description,
                measured_time: alarm.measured_time,
                date: alarm.date,
                Possible_Causes: noncriticalItems.join('\n') // Join items with double newlines
              };
            });
          
            const updatedReportData = prepareReportData(combinedData);
            // console.log("updated=============", updatedReportData);
            setReportData(updatedReportData);
          });
        break;          
        
      case 'restorenoncriticalalarms':
        api['reports']['restore_noncritical_alarm']().then(res => {
          setReportData(prepareReportData(res))
        });
        break;
      case 'ibmsevents':
        api['reports']['ibmsevents_table']().then(res => {
          setReportData(prepareReportData(res))
        })
        break;
      case 'UserControlDetails':
        api['reports']['user_control_deatils']().then(res => {
          setReportData(prepareUserReportData(res))
        })
        break;
      case 'AcknowledgedUser':
        api['reports']['acknowledged_by_user']().then(res => {
          setReportData(prepareReportData(res))
        })
        break;
      case 'IgnoredUser':
        api['reports']['ignored_by_user']().then(res => {
          setReportData(prepareReportData(res))
        })
        break;
      case 'UserLoginReport':
          api['reports']['login_login_details']().then(res => {
            setReportData(prepareUserLoginReportData(res))
          })
          break;
      case 'Device Reports':
      if (option === 'Device Reports') {
      const headings = getColumns(null, true);
      // headings.splice(2, 1, "Date", "Time"); // Replace "Measured Time" with "Date" and "Time"
      let rowData; 
        setReportData({ "Headings": headings, "RowData": rowData });
      } else {
        // For other report options, use default headings and columns
        setReportData(prepareReportData(tableData)); // Pass tableData here
      }
    
             
            break;         
          default:
        // api['reports']['ibmsevents_table']().then(res => {    
        //   setReportData(prepareReportData(res))
        // })
        setReportData([])
        break;



    }




  }

  const onFirstDataRendered1 = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);
  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getColId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);


  const onSelectionChanged = useCallback((event) => {
    // setSelectedRows(event.api.getSelectedRows());
    // window.alert('selection changed, ' + rowCount + ' rows selected');
  }, []);
  const downloadAsPdf = () => {
  const doc = new jsPDF();

const hasDateField = reportData.Headings.some(column => column.field === 'date');
const hasTimeField = reportData.Headings.some(column => column.field === 'time');
const activeTimeField = reportData.Headings.find(column => column.field === 'activeTime');

const tableData = reportData.RowData.map((row) => {
  // Map the row object's keys to match the headers' column.field values
  const newRow = [];

  if (!activeTimeField && hasDateField) {
    newRow.push(row.date !== undefined ? row.date : ''); // Add the "Date" value to newRow if not undefined
  }

  if (!activeTimeField && hasTimeField) {
    newRow.push(row.time !== undefined ? row.time : ''); // Add the "Time" value to newRow if not undefined
  }

  reportData.Headings.forEach((column) => {
    if (
      column.field !== 'date' &&
      column.field !== 'time' &&
      column.field !== 'activeTime' &&
      row.hasOwnProperty(column.field)
    ) {
      newRow.push(row[column.field]); // Add other fields to newRow if they are present in the row
    }
  });

  return newRow;
});

const presentColumns = reportData.Headings.filter(column => {
  return (
    column.field !== 'date' &&
    column.field !== 'time' &&
    column.field !== 'activeTime' &&
    reportData.RowData[0]?.hasOwnProperty(column.field)
  );
});

const rearrangedPdfHeaders = [
  ...(activeTimeField ? [] : (hasDateField ? ['Date'] : [])), // Place the "Date" header first if "activeTime" is absent and "date" is present
  ...(activeTimeField ? [] : (hasTimeField ? ['Time'] : [])), // Place the "Time" header second if "activeTime" is absent and "time" is present
  ...presentColumns.map(column => column.field)
];

// ... (continue with your existing code)

    // console.log("tabledata",tableData)

    const headers = reportData.Headings.map((column)=>column.field);

    // const startX = 5;
    const imgData = require('../../assets/Images/logo.png');  
    const logoWidth = 70; // Adjust the width as needed
    const logoHeight = 40; 
    const startX = 5; // Adjust the starting X position as needed
    const startY = -7; // Adjust the starting Y position as needed
    
    doc.addImage(imgData, 'PNG', startX, startY, logoWidth, logoHeight);
    

  const currentDate = new Date();

// Get current date in "dd/mm/yyyy" format
const day = currentDate.getDate().toString().padStart(2, '0');
const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
const year = currentDate.getFullYear();

const formattedDate = `${day}/${month}/${year}`;

// Get current time in "hh:mm AM/PM" format
const hours = currentDate.getHours();
const minutes = currentDate.getMinutes().toString().padStart(2, '0');
const ampm = hours >= 12 ? 'PM' : 'AM';
const formattedTime = `${(hours % 12) || 12}:${minutes} ${ampm}`;

const currentDateTime = `${formattedDate} ,${formattedTime}`;

const timestampX = doc.internal.pageSize.width - 15; // X position at the top right
const timestampY = 22; // Y position at the top
const timestampFontSize = 10; // Adjust the font size as needed

doc.setFontSize(timestampFontSize);
doc.text(currentDateTime, timestampX, timestampY, { align: 'right' });

    doc.autoTable({
      head: [rearrangedPdfHeaders],
      body: tableData,
      startY: 25, // Adjust this as needed
      theme: 'striped',  // Optional, choose a theme for the table
      styles: {
        halign: 'left', // Center-align column headers
        cellPadding:2, // Adjust cell padding as needed
        // Define border styles
        valign: 'middle', // Vertical alignment
        lineWidth: 0.1, // Width of the table borders
        // lineColor: [0, 0, 0], // Color of the table borders (black in this case)
        // fillStyle: 'F', // Fill the table cells with color
        // textColor: [0, 0, 0], // Text color
      },
      // columnStyles: columnWidths, // Apply custom column widths
      margin: { top: 5 }, // Adjust the margin as needed
    });
    const customerName = "Customer Name"; // Replace with actual customer name
    const downloadedBy = `Downloaded by: ${username}`; // Replace with actual name
    
    // Calculate the width of the customer name and "downloaded by" text
    const customerNameWidth = doc.getStringUnitWidth(customerName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const downloadedByWidth = doc.getStringUnitWidth(downloadedBy) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    
    // Calculate total width of the text
    const totalTextWidth = Math.max(customerNameWidth, downloadedByWidth);
    
    // Center alignment calculations
    const centerX = doc.internal.pageSize.width / 2;
    
    // Positioning for customer name and "Downloaded by" text on the first page
    const firstPageTextX = centerX - totalTextWidth / 2; // Center the text horizontally
    const firstPageTextY = 15; // Fixed position at the top
    const downloadedByY = firstPageTextY +6;
    
    // Set font size for the text
    const textFontSize = 12; // Adjust the font size as needed
    const Font="Arial";
    doc.setFontSize(textFontSize);
    doc.setFont(Font);
    
    // Render the text on the first page only
    const totalPages = doc.internal.getNumberOfPages();
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      doc.setPage(pageNumber);
      if (pageNumber === 1) {
        doc.text(`Customer Name: ${customerName}`, firstPageTextX, firstPageTextY);
        doc.text(downloadedBy, firstPageTextX,downloadedByY); // Adjust vertical position
      }
    }
    const totalPages1 = doc.internal.getNumberOfPages();

    // Iterate through each page and add pagination
    for (let pageNumber = 1; pageNumber <= totalPages1; pageNumber++) {
      doc.setPage(pageNumber);
    
      // Calculate the x and y positions for the pagination
      const paginationX = doc.internal.pageSize.width - 15; // X position at the top right
      const paginationY = doc.internal.pageSize.height - 10; // Y position at the bottom
    
      // Add the page number to the current page
      doc.setFontSize(10);
      doc.text(`Page ${pageNumber} of ${totalPages1}`, paginationX, paginationY, { align: 'right' });
    }

    const filename = `${stitle}.pdf`;
    doc.save(filename);

  };

  const getFilteredData = () => {
    if (age3 === "Device Reports") {
      // Apply filtering or transformation to simulate device reports data
      // For example, you can filter data with specific conditions or modify it
      return reportData["RowData"].filter((row) => row.deviceReport === true);
    } else {
      return reportData["RowData"];
    }
  };


  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChange1 = (event) => {
    setAge1(event.target.value);
  };
  const handleDropdownChange = (event) => {
      // console.log("cameeeeeeeeeeeeeeeeeeeeeee")
  const selectedValue = event.target.value;
    // console.log("deviceid---------------------",deviceid)
    setAge3(selectedValue)
    // console.log("seleetedvalueeeeeeee==>",selectedValue,typeof selectedValue)
    if(selectedValue === "1 Day"){
      api.alarmreports.alarmsfor1day(deviceid).then((res)=>{
      // console.log("1 day------------------------",res)
      setTableData(res);
      setReportData(prepareReportData(res));
    })
    }else if(selectedValue === "1 week"){
      api.alarmreports.alarmsfor1week(deviceid).then((res)=>{
        // console.log("1 week----------------------",res)
        setWeek1(res);
        setReportData(prepareReportData(res));
      })
    }else if(selectedValue === "1 month"){
      api.alarmreports.alarmsfor1month(deviceid).then((res)=>{
        // console.log("1 month----------------------",res);
        setMonth1(res);
        setReportData(prepareReportData(res));
      })
    }else if(selectedValue === "3 months"){
      api.alarmreports.alarmsfor3months(deviceid).then((res)=>{
        // console.log("3 months----------------------",res);
        setMonth3(res);
        setReportData(prepareReportData(res));
      })
    }else if(selectedValue === "6 months"){
      api.alarmreports.alarmsfor6months(deviceid).then((res)=>{
        // console.log("6 months----------------------",res);
        setMonth6(res);
        setReportData(prepareReportData(res));
      })
    }else if(selectedValue === "1 year"){
      api.alarmreports.alarmsfor1year(deviceid).then((res)=>{
        // console.log("1yearr----------------------",res);
        setYear1(res);
        setReportData(prepareReportData(res));
            })
          }
     
  };

  const handledeviceChange = (name, id) => {
    setDName(name);
    setDeviceid(id)
  };
  
  const handlefloorchange = (name, id) => {
    // console.log("name",name)
    setDName(name);
    setDeviceid(id)
    setFId(id)
    setFdata(name);
    let z_data = [],
    d_data = [];
  data.sort(function (a, b) {
    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
  });
  data.filter((_each, i) => {
    if (
      _each.zone_type === "GL_LOCATION_TYPE_ZONE" &&
      _each.zone_parent === id
    ) {
      z_data.push(_each);
      data.filter((eachObj) => {
        if (eachObj.zone_parent === _each.uuid) {
          if(eachObj.zone_type=='NONGL_SS_AHU'){
          d_data.push(eachObj);
          }
        }
      });
    }
    d_data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
    setDData(d_data)
  })
    
  };

  const handleformatChange=(selectedformat)=>{
    // const selectedformat = event.target.value;
    // console.log("Selected format:", selectedformat); // Check selected format
    setFormat(selectedformat);
    if(selectedformat === "download_csv"){
      // console.log("csv-----------donloadd-")
      onBtExport();
    
    }else 
    if (selectedformat === 'download_pdf') {
      // console.log("pdf----------------------")
  const doc = new jsPDF();
 
  // doc.text('This is a PDF content.', 10, 10);
  // Add more content to the PDF as needed

  // Define column widths here
  
  
  const columnWidths = {
    Date: 40,
    Time: 40,
    DeviceName: 60,
    Parameter: 50,
    Description: 60,
    // Add more columns and their widths if needed
  };

  const tableData = reportData.RowData.map((row) => {
    // Map the row object's keys to match the headers' column.field values
    const newRow = [];
    const dateField = reportData.Headings.find(column => column.field === 'date');
    const timeField = reportData.Headings.find(column => column.field === 'time');
  
    newRow.push(row[dateField.field]); // Add the "Date" value to the beginning of newRow
    newRow.push(row[timeField.field]); // Add the "Time" value to the second position of newRow
  
    reportData.Headings.forEach((column) => {
      if (column.field !== 'date' && column.field !== 'time') {
        newRow.push(row[column.field]); // Add other fields to newRow
      }
    });
  
    return newRow;
  });

  const rearrangedPdfHeaders = [
    'Date', // Place the "Date" header first
    'Time', // Place the "Time" header second
    ...reportData.Headings
      .filter(column => column.field !== 'date' && column.field !== 'time') // Exclude "Date" and "Time" fields
      .map(column => column.field)
  ];

  const headers = reportData.Headings.map((column) => column.field);
  // Calculate the total width of the table
  const totalWidth = headers.reduce((total, field) => total + columnWidths[field], 0);
  

  // Calculate the starting x position to center the table
  const imgData = require('../../assets/Images/logo.png');  
    const logoWidth = 70; // Adjust the width as needed
    const logoHeight = 40; 
    const startX = 5; // Adjust the starting X position as needed
    const startY = -7; // Adjust the starting Y position as needed
    
    doc.addImage(imgData, 'PNG', startX, startY, logoWidth, logoHeight);

  const currentDate = new Date();

// Get current date in "dd/mm/yyyy" format
const day = currentDate.getDate().toString().padStart(2, '0');
const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
const year = currentDate.getFullYear();

const formattedDate = `${day}/${month}/${year}`;

// Get current time in "hh:mm AM/PM" format
const hours = currentDate.getHours();
const minutes = currentDate.getMinutes().toString().padStart(2, '0');
const ampm = hours >= 12 ? 'PM' : 'AM';
const formattedTime = `${(hours % 12) || 12}:${minutes} ${ampm}`;

const currentDateTime = `${formattedDate} ,${formattedTime}`;

const timestampX = doc.internal.pageSize.width - 15; // X position at the top right
const timestampY = 22; // Y position at the top
const timestampFontSize = 10; // Adjust the font size as needed

doc.setFontSize(timestampFontSize);
doc.text(currentDateTime, timestampX, timestampY, { align: 'right' });



  const pdfHeaders = reportData.Headings.map((column) => {
    if (column.field === 'date') {
      return 'Date';
    } else if (column.field === 'time') {
      return 'Time';
    } else {
      return column.field;
    }
  });

  doc.autoTable({
    head: [rearrangedPdfHeaders],
    body: tableData,
    startY: 25, // Adjust this as needed
    theme: 'striped',  // Optional, choose a theme for the table
    styles: {
      halign: 'left', // Center-align column headers
      cellPadding:2, // Adjust cell padding as needed
      // Define border styles
      valign: 'middle', // Vertical alignment
      lineWidth: 0.1, // Width of the table borders
      // lineColor: [0, 0, 0], // Color of the table borders (black in this case)
      // fillStyle: 'F', // Fill the table cells with color
      // textColor: [0, 0, 0], // Text color
    },
    columnStyles: columnWidths, // Apply custom column widths
    margin: { top: 10 }, // Adjust the margin as needed
  });

  const customerName = "Customer Name"; // Replace with actual customer name
  const downloadedBy = `Downloaded by: ${username}`; // Replace with actual name
  
  // Calculate the width of the customer name and "downloaded by" text
  const customerNameWidth = doc.getStringUnitWidth(customerName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const downloadedByWidth = doc.getStringUnitWidth(downloadedBy) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  
  // Calculate total width of the text
  const totalTextWidth = Math.max(customerNameWidth, downloadedByWidth);
  
  // Center alignment calculations
  const centerX = doc.internal.pageSize.width / 2;
  
  // Positioning for customer name and "Downloaded by" text on the first page
  const firstPageTextX = centerX - totalTextWidth / 2; // Center the text horizontally
  const firstPageTextY = 15; // Fixed position at the top
  const downloadedByY = firstPageTextY +6;
  
  // Set font size for the text
  const textFontSize = 12; // Adjust the font size as needed
  const Font="Arial";
  doc.setFontSize(textFontSize);
  doc.setFont(Font);
  
  // Render the text on the first page only
  const totalPages = doc.internal.getNumberOfPages();
  for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
    doc.setPage(pageNumber);
    if (pageNumber === 1) {
      doc.text(`Customer Name: ${customerName}`, firstPageTextX, firstPageTextY);
      doc.text(downloadedBy, firstPageTextX,downloadedByY); // Adjust vertical position
    }
  }

  const totalPages1 = doc.internal.getNumberOfPages();

  // Iterate through each page and add pagination
  for (let pageNumber = 1; pageNumber <= totalPages1; pageNumber++) {
    doc.setPage(pageNumber);
  
    // Calculate the x and y positions for the pagination
    const paginationX = doc.internal.pageSize.width - 15; // X position at the top right
    const paginationY = doc.internal.pageSize.height - 10; // Y position at the bottom
  
    // Add the page number to the current page
    doc.setFontSize(10);
    doc.text(`Page ${pageNumber} of ${totalPages1}`, paginationX, paginationY, { align: 'right' });
  }

  // Save the PDF
  doc.save('report.pdf');
}    
 }
  return (
     <div>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={5}>
            <FormControl
              variant="filled"
              className={classes.formControl}
              style={{ width: "max-content", minWidth: "100%", backgroundColor: "#eeeef5",marginLeft:"0vh"}}
            >
              {/* <InputLabel id="demo-simple-select-outlined-label">
              Reports
              </InputLabel> */}
              <Select
                labelId="filled-hidden-label-small"
                id="demo-simple-select-outlined"
                label="Floor"
                
                value={stitle || ''}
                style={{fontWeight:"bold",borderRadius:'0.8vw' }}
                // onChange={handlefloorchange}
                className={classes.select}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                disableUnderline
              >
                  {reportOptions.map(_item => (
            _item.title !== "User Login Report" || _item.title !== "Trend Log Comparision Report" || _item.title !== "Schedule Activity Report" || _item.title !== "Activites Per User Report" || _item.title !== "Activites Per Server Report" ?
              <MenuItem key={_item.option} value={_item.option} onClick={() => handleReportSelection(_item.api, _item.option)}>{_item.title}</MenuItem>
              :
              <MenuItem key={_item.option} value={_item.option} style={{ "backgroundColor": 'Gray' }}>{_item.title}</MenuItem>
          ))}
              </Select>
            </FormControl>
          </Grid>
       

          </Grid>
          </Grid>
      <div>
     {stitle === 'Device Reports' && (
 <div>
 <Grid container spacing={1}>
   <Grid item xs={3}>
   <FormControl
                              variant="outlined"
                              size="small"
                              className={classes.formControl}
                              style={{
                                width: "100%",
                                backgroundColor: "#eeeef5",
                                fontFamily: "Arial",
                              }}
                            >
                        <InputLabel  id="demo-simple-select-outlined-label">Floor</InputLabel>

                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Floor"
                                style={{
                                  fontWeight: "bold",
                                  height: "6vh",
                                  borderRadius: '0.8vw',
                                  fontFamily: "Arial"
                                }}
                                value={fdata}
                                className={classes.select}
                                onChange={handleChange1}
                                disableUnderline
                              >
                                {floor.map((_item) => (
                                  <MenuItem
                                    key={_item.id}
                                    value={_item.name}
                                    onClick={() => handlefloorchange(_item.name, _item.id)}
                                  >
                                    {_item.name}
                                  </MenuItem>
                                ))}
                              </Select>
    </FormControl>
    </Grid>
    <Grid item xs={3}>
     <FormControl
       variant="outlined" size="small"
       className={classes.formControl}
       style={{
         width: "100%",
         backgroundColor: "#eeeef5",
         fontFamily: "Arial",
         

       }}
     >
                        <InputLabel  id="demo-simple-select-outlined-label">Device</InputLabel>

       <Select
   labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
         label="Device"
         style={{
           fontWeight: "bold",
           height: "6vh",
           borderRadius: '0.8vw',
           fontFamily: "Arial"
         }}
         value={dname}
         className={classes.select}
         onChange={handleChange}
         disableUnderline
       >
         {ddata.map((_item) => (
                        <MenuItem
                          key={_item.uuid}
                          value={_item.name}
                          onClick={() => handledeviceChange(_item.name, _item.uuid)}
                        >
                          {_item.name}
                        </MenuItem>
                      ))}
                      </Select>
     </FormControl>
     </Grid>
     <Grid item xs={3}>
     <FormControl
       variant="outlined" size="small"
       className={classes.formControl}
       
       style={{
         width: "100%",
         backgroundColor: "#eeeef5",
         fontFamily: "Arial",
         

       }}
       
     >
       <InputLabel  id="demo-simple-select-outlined-label">Time
       </InputLabel>
       <Select
   labelId="demo-simple-select-outlined-label"
   id="demo-simple-select-outlined"
         label="Floor"
         style={{
           fontWeight: "bold",
           height: "6vh",
           borderRadius: '0.8vw',
           fontFamily: "Arial"
         }}
         value={age3}
         className={classes.select}
         onChange={handleDropdownChange}
         disableUnderline
       >
           <MenuItem value="1 Day">1 day</MenuItem>
          <MenuItem value="1 week">1 week</MenuItem>
          <MenuItem value={"1 month"}>1 month</MenuItem>
          <MenuItem value={"3 months"}>3 months</MenuItem>
          <MenuItem value={"6 months"}>6 months</MenuItem>
          <MenuItem value={"1 year"}>1 year</MenuItem>



        
       </Select>
     </FormControl>
     </Grid>
     <Grid item xs={3}>
<Grid container spacing={2} alignItems="center">
<Grid item xs={3}>
<FormControl
      component="fieldset"
      variant="filled"
      size="small"
      className={classes.formControl}
      style={{
        width: '100%',
        fontFamily: 'Arial',
        display: 'flex', // Set display to flex
        justifyContent: 'space-between' // Space evenly between elements
      }}
    >
      {/* <RadioGroup
    aria-label="format"
    name="format"
    value={format}
    className={classes.radioGroup}
    onChange={handleformatChange}
    row // Set the 'row' prop to arrange radio buttons horizontally
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
        <FormControlLabel
          value="download_csv"
          control={<Radio />}
          label="Download to CSV"
          labelPlacement="end" 
        />
        <FormControlLabel
          value="download_pdf"
          control={<Radio />}
          label="Download to PDF"
        />
        </div>
      </RadioGroup> */}
      <ButtonGroup
  color="primary"
  aria-label="format"
  className={classes.buttonGroup}
  size="small"
>
  <Button
    variant={format === "download_csv" ? "contained" : "outlined"}
    onClick={() => handleformatChange("download_csv")}
    style={{ padding: '13px 20px', fontSize: '12px' }}
  >
    Export to CSV
  </Button>
  {/* Add some spacing */}
  <div style={{ margin: '0 5px' }}></div>
  <Button
    variant={format === "download_pdf" ? "contained" : "outlined"}
    onClick={() => handleformatChange("download_pdf")}
    style={{ padding: '13px 20px', fontSize: '12px' }}
  >
    Download As PDF
  </Button>
</ButtonGroup>


      
    </FormControl>
</Grid>
</Grid>
   </Grid>
 </Grid>
</div>


  
    )}
      </div>
      {stitle !== 'Device Reports' && (
    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', gap: '10px' }}>
    <ButtonGroup
      color="primary"
      aria-label="format"
      className={classes.buttonGroup}
      size="small"
    >
      <Button onClick={() => sizeToFit()} size='small' style={{ padding: '4px 10px', fontSize: '12px' }}>
        Size to Fit
      </Button>
      <div style={{ margin: '0 3px' }}></div>
      <Button onClick={() => autoSizeAll(false) } style={{ padding: '4px 10px', fontSize: '12px' }}>Auto-Size All</Button>
    </ButtonGroup>
    <ButtonGroup
      color="primary"
      aria-label="format"
      className={classes.buttonGroup}
      size="small"
    >
      <Button onClick={() => onBtExport()} size='small' style={{ padding: '4px 10px', fontSize: '12px' }}>
        Export to CSV
      </Button>
      <div style={{ margin: '0 3px' }}></div>
      <Button onClick={() => downloadAsPdf()} size="small"style={{ padding: '4px 10px', fontSize: '12px' }} >Download as PDF</Button>
    </ButtonGroup>
  </div>
  
      )}



      {isloaded === false ?
        <div style={{ "textAlign": "center", "marginTop": "150px" }}>
          <CircularProgress className={classes.loader} />
        </div>
        :
        <div className="ag-theme-alpine" style={{ height: 450, width: '100%',marginTop:"1vh" }}>
          <AgGridReact
            ref={gridRef}
            // rowData={reportData["RowData"]}
            // rowData={age3 === "Device Reports" ? deviceReportData : reportData["RowData"]} // Use deviceReportData only for "Device Reports" option
            rowData={getFilteredData()} 
            columnDefs={reportData["Headings"]}
            rowSelection={'multiple'}
            onSelectionChanged={onSelectionChanged}
            pagination={true}
            paginationPageSize={10}
            onFirstDataRendered={onFirstDataRendered1}
            suppressMovable="true"
            suppressMenuHide="true"
          >
          </AgGridReact>
        </div>
      }
    </div>
  )
}

export default GlEventsViewer