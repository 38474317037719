import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {Grid,Divider} from '@material-ui/core';
import {Card} from '@material-ui/core';
import api from './../../api';
import theme from '../../responsive/TextTypography';
import Warning from "components/Typography/Warning";
import Danger from "components/Typography/Danger";
import Success from 'components/Typography/Success';
import SemiCircleProgressBar from "react-progressbar-semicircle";

const useStyles = makeStyles(theme => ({
    root: {
    flexGrow: 1,
    marginTop:'-1vh'
  },
  paper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    // borderRadius: '14px',
    borderRadius:"6px",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    height: '10vh',
    marginTop:"1vh",
    opacity:'1'
  },
  semicircularbar:{
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
        marginTop:'-0.5vh',
        marginLeft:'-1.5vh'
      },
      '@media (min-width:600px) and (max-width:959.95px)': {//sm
        marginTop:'-1vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:960px) and (max-width:1279.95px)': {//md
        marginTop:'-2vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
        marginTop:'-2vh',
        marginLeft:'-0.1vh'
      },
      '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
        marginTop:'-1vh',
        marginLeft:'0.5vh'
      },
  },
  text: {
    fontSize: "14px",
    color: " #292929",
    fontFamily: "Arial"
  },
  statusFont: {
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      textAlign:'center',
      fontSize: '1.5vh',
      color:'black',
      whiteSpace:'nowrap'
    },
    '@media (min-width:600px) and (max-width:959.95px)': {//sm
      textAlign:'center',
      fontSize: '1.8vh',
      color:'black',
      whiteSpace:'nowrap'
    },
    '@media (min-width:960px) and (max-width:1279.95px)': {//md
      textAlign:'center',
      fontSize: '1.8vh',
      color:'black',
      whiteSpace:'nowrap'
    },
    '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
      textAlign:'center',
      fontSize: '2.2vh',
      whiteSpace:'nowrap',
      color:'black'
    },
    '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
      textAlign:'center',
      fontSize: '2.3vh',
      color:'black',
      whiteSpace:'nowrap',
    },
  }
}));

 function UpsEmsLanding(props) {
  const classes = useStyles();
  const alerts = useSelector(state => state.alarm.alarmData)
  const [eachChillerData, setEachChillerData] = React.useState([])
  const [criticalAlertsChiller, setcriticalAlertsChiller] = React.useState(0);
  const [soluAlertsChiller, setsoluAlertsChiller] = React.useState(0);
  const [openerr,setOpenerr] = React.useState(false);
  const [errmsg,setErrmsg] = React.useState('');

  const numEmptyCards = 4 - props.device.length; // Adjust the total number as needed  
  const emptyCardsDev = Array.from({ length: numEmptyCards }, (_, index) => (
      <Grid item xs sm md lg xl xxl>
      <Card style={{boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",backgroundColor:"#fcfafa", "border-radius": "10px", height: props.type === 'CPM' ?"13.5vh":"11.5vh" }}>
      </Card> 
      </Grid>  
  ));

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const onClickIssue = (id,name)=> {
    if(props.type=='UPS'){
      props.history.push({
        pathname: `/admin/glUps`,
        state: {
          flr_id: props.fid,
          fname: props.fdata,
          dev_id: id,
          dev_name: name
        }
      })
    }else if(props.type=='EMS'){
      props.history.push({
        pathname: `/admin/glEnergyMeter`,
        state: {
          flr_id:props.fid,
          fname:props.fdata,
          dev_id: id,
          dev_name: name
        }
      })
    }else if(props.type=='CPM'){
      props.history.push({
        pathname: `/admin/GlChillerPage`,
        state: {
          data: id,
          name: name
        }
      });
    }
  }

  function EachDevCardForDetails(type,element,index) {
    console.log("element==========ups===========",element)
    let active = {}
    if(type =='UPS'){
      const input_ph_volt_values = [element.input_ph_volt_A,
        element.input_ph_volt_B,
        element.input_ph_volt_C,].map((value) => parseInt(value, 10));
        const sum = input_ph_volt_values.reduce((total, value) => total + value, 0);
    const status = (sum / input_ph_volt_values.length).toFixed(2); 
        const output_ph_active_values = [element.ph_A_Out_acti_Pow,
          element.ph_B_Out_acti_Pow,
          element.ph_C_Out_acti_Pow,].map((value) => parseInt(value, 10));
        const sum1 = output_ph_active_values.reduce((total, value) => total + value, 0);
        const load = sum1 / output_ph_active_values.length
        active["Alarm"] = 0
        active["Status"] = status;
        active["Load"] = load;
        active["UPS Temperature"]='19°C'
        active["Battery Charge"]='47V'
        // if (element.kW) {
          active["kW"] = element.kW
        // }
      }else if(type =='EMS'){
        active["Alarm"] = 0
        if(element.PF_Ttl){
          active["Avg PF[mA]"] = element.PF_Ttl
        }
        if(element.Cur_Avg){
          active["Current"] = element.Cur_Avg
        }
        if(element.Act_Pwr_Ttl){
          active["Active Power[W]"] = element.Act_Pwr_Ttl
        }
        if(element.Freq){
          active["Frequency[Hz]"] = element.Freq
        }
        if(element.Prsnt_Dmd){
          active["Kilo Watt"] = element.Prsnt_Dmd
        }   
      }
    return(
      <>
          <Grid container item xs={12} spacing={1} className={classes.statusFont}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}  style={{ textAlign: 'left',marginLeft:'0.5vh',fontWeight:'bold'}}>{element.name}</Grid>
          </Grid>
          <Grid container item xs={12} spacing={1} style={{marginLeft:'0vh'}}>
            {Object.entries(active).map(([key, value]) => ( 
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <Card className={classes.paper} style={{ height: "11.5vh"}}>
                                    <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.statusFont}>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{color: "black",whiteSpace:'nowrap',marginLeft: key=='UPS Temperature'?'-8vh':key=='Battery Charge'?'-5vh':key=='Active Power[W]'?'-4vh':key=='Frequency[Hz]'?'-5vh':key=='Avg PF[mA]'?'-2vh':'0vh'}}>                                
                                          {key}
                                        </Grid>
                                        {key =='Load'?
                                            <>
                                                    <div className={classes.semicircularbar}>
                                                    <SemiCircleProgressBar
                                                        // strokeWidth={window.innerWidth<600? 20:window.innerWidth>600?20 : window.innerWidth<960? 30:window.innerWidth<1280?30:window.innerWidth<1920?30:40}
                                                        stroke="#0123b4"
                                                        strokeWidth={20}
                                                        diameter={100}
                                                        orientation="up"
                                                        percentage={value} // Set the value here
                                                    // showPercentValue
                                                    />
                                                </div>
                                                <div style={{ marginTop: '-2.5vh', fontSize: '10px' }}>{formatter.format(value)}</div>
                                        </>
                                        :
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{color: "#0123b4",fontWeight:'bold'}}>
                                          {/* {value!='0'?formatter.format(value):typeof value=='string'?'N/A':value} */}
                                          {value}
                                        </Grid>
                                      }
                                        
                                    </Grid>
                                </Card>
                            </Grid>
                          ))}
          </Grid>
      </>
    )
  }

  useEffect(()=>{
    let campus_id = localStorage.getItem('campusID')
    api.floor.devicemap(campus_id,'CHILLER').then((res) => {
      if (res.length !== 0) {
        let sdevices = []
        let CriticalTotal = 0
        let solutionTotal = 0

        if (alerts.system.length === 0 && alerts.solution.length === 0) {
          let con = 0
          res.map(element => {
            let obj = {}
            con++
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            obj["alerts_cri"] = 0
            obj["alerts_solu"] = 0
            sdevices.push(obj)
            if (res.length === con) {
              setEachChillerData(sdevices)
            }
            return element
          })
        }

        if (alerts.system.length > 0 && alerts.solution.length > 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.system.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                CriticalTotal++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  setcriticalAlertsChiller(CriticalTotal)
                  let si = 0
                  let counts = 0
                  alerts.solution.map(ele1 => {
                    if (element.name === ele1.device_name) {
                      counts++
                      si++
                      solutionTotal++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachChillerData(sdevices)
                        setsoluAlertsChiller(solutionTotal)
                      }
                    } else {
                      counts++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachChillerData(sdevices)
                        setsoluAlertsChiller(solutionTotal)
                      }
                    }
                    return ele1
                  })

                }
              } else {
                count++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  setcriticalAlertsChiller(CriticalTotal)
                  let si = 0
                  let counts = 0
                  alerts.solution.map(ele => {
                    if (element.name === ele.device_name) {
                      counts++
                      si++
                      solutionTotal++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachChillerData(sdevices)
                        setsoluAlertsChiller(solutionTotal)
                      }
                    } else {
                      counts++
                      if (alerts.solution.length === counts) {
                        obj["alerts_solu"] = si
                        sdevices.push(obj)
                        setEachChillerData(sdevices)
                        setsoluAlertsChiller(solutionTotal)
                      }
                    }
                    return ele
                  })
                }
              }
              return ele
            })
            return element
          })
        }

        if (alerts.system.length > 0 && alerts.solution.length === 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.system.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                CriticalTotal++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  obj["alerts_solu"] = 0
                  setcriticalAlertsChiller(CriticalTotal)
                  sdevices.push(obj)
                  setEachChillerData(sdevices)
                }
              } else {
                count++
                if (alerts.system.length === count) {
                  obj["alerts_cri"] = ci
                  obj["alerts_solu"] = 0
                  setcriticalAlertsChiller(CriticalTotal)
                  sdevices.push(obj)
                  setEachChillerData(sdevices)
                }
              }
              return ele
            })
            return element
          })


        }

        if (alerts.system.length === 0 && alerts.solution.length > 0) {
          res.map(element => {
            let obj = {}
            obj["name"] = element.name
            obj["ssid"] = element.ssid
            obj["type"] = element.type
            let count = 0
            let ci = 0
            alerts.solution.map(ele => {
              if (element.name === ele.device_name) {
                count++
                ci++
                solutionTotal++
                if (alerts.solution.length === count) {
                  obj["alerts_solu"] = ci
                  obj["alerts_cri"] = 0
                  sdevices.push(obj)
                  setsoluAlertsChiller(solutionTotal)
                  setEachChillerData(sdevices)
                }
              } else {
                count++
                if (alerts.solution.length === count) {
                  obj["alerts_solu"] = ci
                  obj["alerts_cri"] = 0
                  sdevices.push(obj)
                  setsoluAlertsChiller(solutionTotal)
                  setEachChillerData(sdevices)
                }
              }
              return ele
            })
            return element
          })
        }

      }
      else {
        setEachChillerData([]);
      }
    })
    .catch((error)=>{
      setOpenerr(true)
      if(error.response){
        setErrmsg(error.response.data.message)
        }else{
          setErrmsg('')
        }
  })
  },[alerts.system.length,alerts.solution.length])

  return (
    <div className={classes.root}>
        <ThemeProvider theme={theme}>
        <Grid container spacing={1} style={{marginTop:'1vh'}}>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} sm={12} md={props.type === 'CPM' ? 4 :2} lg={props.type === 'CPM' ? 4 :2} xl={props.type === 'CPM' ? 4 :2} xxl={props.type === 'CPM' ? 4 :2}>
                    <Card className={classes.paper} style={{height:props.type === 'CPM' ?"16vh":"14vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {props.type === 'CPM' ?
                            <Grid container item xs={12} spacing={1}>
                                      <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}  style={{display:'flex',justifyContent:"center",alignItems:'center'}}>
                                          <Grid container item xs={12} spacing={1} direction='column' style={{ display: 'flex', justifyContent: 'center' ,alignItems:'center'}}>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                              <div style={{whiteSpace:"nowrap",fontSize:'4.5vh',fontWeight:'bold',color:'#CE1E1E'}}>
                                                  {parseInt(criticalAlertsChiller) + parseInt(soluAlertsChiller)}
                                              </div>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}  className={classes.statusFont}>
                                              <div style={{whiteSpace:"nowrap",marginLeft:'-3.7vh'}}>Total Alarms</div>
                                            </Grid>
                                          </Grid>
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                                          <Divider orientation="vertical" flexItem style={{height:"13vh"}}/>
                                      </Grid>
                                      <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}  className={classes.statusFont}>
                                          <Grid container item xs={12} spacing={1} style={{display:'flex',justifyContent:"center",alignItems:'center',marginTop:'2.7vh'}}>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                Critical
                                              </Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <div style={{whiteSpace:"nowrap",marginLeft:'4vh',color:'#CE1E1E'}}>
                                                  {parseInt(criticalAlertsChiller)}
                                                  </div>
                                              </Grid>
                                            </Grid>
                                            <Grid container item xs={12} spacing={1}  style={{display:'flex',justifyContent:"center",alignItems:'center',marginTop:'1.5vh'}}>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <div style={{marginLeft:'-0.5vh'}}>Low</div>
                                              </Grid>
                                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                                <div style={{whiteSpace:"nowrap",marginLeft:'4vh',fontWeight:'bold',color:'#F2AA1A'}}>
                                                  {parseInt(soluAlertsChiller)}
                                                  </div>
                                              </Grid>
                                            </Grid>
                                      </Grid>
                            </Grid>
                            :
                            <Grid container item xs={12} spacing={1} direction='column' alignItems='center'>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{fontSize:'2.5rem'}}><Success>0</Success></Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                <Typography variant='h3' style={{fontWeight:'bold',marginLeft:'-1vh',whiteSpace:'nowrap'}}>Total Alarms</Typography>
                            </Grid>
                            </Grid>
                    }
                        
                    </Card>
                </Grid> 
                <Grid item xs={12} sm={12} md={props.type === 'CPM' ? 8 : 10} lg={props.type === 'CPM' ? 8 : 10} xl={props.type === 'CPM' ? 8 : 10} xxl={props.type === 'CPM' ? 8 : 10}>
                    <Card className={classes.paper} style={{ height:props.type === 'CPM' ?"16vh":"14vh"}}>
                         {/* {FormRow(props.type,props.device)}   */}
                         <Grid container item xs={12} spacing={1} direction='row'>
                            {props.device.map((res)=>
                            <>
                              <Grid item xs sm md lg xl xxl>
                                <Card style={{ boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa","border-radius": "10px", height: props.type === 'CPM' ?"13.5vh":"11.5vh" }}>
                                    <Grid container item xs={12} direction='column' style={{display:'inline-flex',"justify-content": "center","align-content": "center", whiteSpace: "nowrap", cursor: "pointer"}} onClick={() => onClickIssue(res.ssid, res.name)}>
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.statusFont}>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>{res.name}</Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                                              {props.type=='CPM'?
                                              <>{parseInt(res.alerts_cri) + parseInt(res.alerts_solu) === 0 ?
                                                <Success style={{ justifyContent: 'center', textAlign: 'center',fontFamily:'helvetica'}}><Typography style={{fontSize:'3.5vh'}}>{parseInt(res.alerts_cri) + parseInt(res.alerts_solu)}</Typography></Success>
                                                : parseInt(res.alerts_cri) + parseInt(res.alerts_solu) === 1 ?
                                                  <Warning style={{ justifyContent: 'center', textAlign: 'center',fontFamily:'helvetica'}}><Typography style={{fontSize:'3.5vh'}}>{parseInt(res.alerts_cri) + parseInt(res.alerts_solu)}</Typography></Warning> 
                                                  :
                                                  <Danger style={{ justifyContent: 'center', textAlign: 'center',fontFamily:'helvetica'}}><Typography style={{fontSize:'3.5vh'}}>{parseInt(res.alerts_cri) + parseInt(res.alerts_solu)}</Typography></Danger> 
                                              }</>
                                              :
                                              0
                                            }
                                            
                                            </Grid>
                                        </Grid>       
                                    </Grid>
                                </Card>           
                              </Grid>
                              </>
                            )}
                            {emptyCardsDev}
                          </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Grid>{
  console.log("eachChillerData",eachChillerData,'props.device',props.device)}
        {props.type == 'CPM'?<></>:
        <Grid container spacing={1}>
        <Grid container item xs={12} spacing={2}>
                   {props.device.map((element, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Card className={classes.paper} style={{ height: "32.5vh"}}>
                      <>{EachDevCardForDetails(props.type,element, index)}</>
                </Card>
            </Grid>
                    ))}
        </Grid>
    </Grid> 
        }
        </ThemeProvider>    
    </div>
  )
}
export default withRouter(UpsEmsLanding)