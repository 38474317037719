
import React from 'react';
import FaultChart from './FaultChart';
import {  makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Card,
  ButtonBase,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      width: "100%",
    },
   faultCard:{
    width:"100%",
    height:"100%"
   }
  }))
const App = () => {
  const classes = useStyles();

  // Sample data for open and close faults
  const openFaults = [
    {
        "Devicename": "AHU-1",
        "source": "Fault from DDC",
        "open_count": 1
    },
    {
        "Devicename": "AHU-2",
        "source": "Fault from DDC",
        "open_count": 2
    },
    {
      "Devicename": "AHU-3",
      "source": "Fault from DDC",
      "open_count": 3
  },
  {
    "Devicename": "AHU-4",
    "source": "Fault from DDC",
    "open_count": 2
},
{
  "Devicename": "AHU-5",
  "source": "Fault from DDC",
  "open_count": 5
},
{
  "Devicename": "AHU-6",
  "source": "Fault from DDC",
  "open_count": 1
},
{
  "Devicename": "AHU-7",
  "source": "Fault from DDC",
  "open_count": 2
},
{
  "Devicename": "Chiller-1",
  "source": "Fault from DDC",
  "open_count": 3
},
{
  "Devicename": "Chiller-2",
  "source": "Fault from DDC",
  "open_count": 3
},
{
"Devicename": "Chiller-3",
"source": "Fault from DDC",
"open_count": 4
},
{
"Devicename": "Chiller-4",
"source": "Fault from DDC",
"open_count": 1
},
{
"Devicename": "Chiller-5",
"source": "Fault from DDC",
"open_count": 7
},
]

  const closeFaults = [
    {
      "Devicename": "AHU-1",
      "source": "Fault from DDC",
      "close_count": 5
  },
  {
      "Devicename": "AHU-2",
      "source": "Fault from DDC",
      "close_count": 6
  },
  {
    "Devicename": "AHU-3",
    "source": "Fault from DDC",
    "close_count": 5
  },
  {
    "Devicename": "AHU-4",
    "source": "Fault from DDC",
    "close_count": 4
  }, {
    "Devicename": "AHU-5",
    "source": "Fault from DDC",
    "close_count": 6
  }, {
    "Devicename": "AHU-6",
    "source": "Fault from DDC",
    "close_count": 5
  }, 
  {
    "Devicename": "AHU-7",
    "source": "Fault from DDC",
    "close_count": 6
  },
  {
    "Devicename": "Chiller-1",
    "source": "Fault from DDC",
    "close_count": 1
  },{
    "Devicename": "Chiller-2",
    "source": "Fault from DDC",
    "close_count": 3
  },{
    "Devicename": "Chiller-3",
    "source": "Fault from DDC",
    "close_count": 4
  },{
    "Devicename": "Chiller-4",
    "source": "Fault from DDC",
    "close_count": 6
  },{
    "Devicename": "Chiller-5",
    "source": "Fault from DDC",
    "close_count": 5
  },
  ];



  return (
    <div className="App">
      <h1>Fault Stats for March-2024</h1>
      <Grid container xs={12}>
        <Card className={classes.faultCard}>
      <FaultChart openFaults={openFaults} closeFaults={closeFaults} />
      </Card>
      </Grid>
    </div>
  );
};

export default App;
