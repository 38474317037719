import React, { useEffect, useState } from 'react'
import api from '../../api'
import Success from 'components/Typography/Success';
import TimeSeries from "../TimeSeries.js";
import TimeSeries1 from "../TimeS";
import Devicetrend from 'views/Devicetrend';
import { Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";
import { format, compareAsc } from 'date-fns';
import {
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%"

  },
  paper: {
    background:'#FFFFFF 0% 0% no-repeat padding-box',
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    // boxShadow: '0px 4px 20px #0123B41A',
    // backgroundColor: 'white',
    // borderRadius: '14px',
    borderRadius:"6px",
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    opacity:'1'
  },
  formControl: {
    autosize: true,
    clearable: false
  },
  select: {
    "&:after": {
      borderBottomColor: "blue",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      backgroundColor:"#0123b4",borderRadius:"8px"
    },
    "& .MuiSelect-root ": {
      marginTop:"-2vh"
    }
  },
   headingFont: {
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      textAlign:'center',
      fontSize: '1.5vh',
      color:'black',
    },
    '@media (min-width:600px) and (max-width:959.95px)': {//sm
      textAlign:'center',
      fontSize: '1.6vh',
      color:'black'
    },
    '@media (min-width:960px) and (max-width:1279.95px)': {//md
      textAlign:'center',
      fontSize: '1.5vh',
      color:'black'
    },
    '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
      textAlign:'center',
      fontSize: '1.8vh',
      whiteSpace:'nowrap',
      color:'black'
    },
    '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
      textAlign:'center',
      fontSize: '1.8vh',
      color:'black',
      whiteSpace:'nowrap',
    },
  },
  statusFont: {
    '@media (min-width:0px) and (max-width:599.95px)': {//xs
      textAlign:'center',
      fontSize: '3.3vh',
      color:'black',
    },
    '@media (min-width:600px) and (max-width:959.95px)': {//sm
      textAlign:'center',
      fontSize: '3.5vh',
      color:'black'
    },
    '@media (min-width:960px) and (max-width:1279.95px)': {//md
      textAlign:'center',
      fontSize: '2.7vh',
      color:'black'
    },
    '@media (min-width:1280px) and (max-width:1919.95px)': {//lg
      textAlign:'center',
      fontSize: '3.5vh',
      whiteSpace:'nowrap',
      color:'black'
    },
    '@media (min-width:1920px) and (max-width:2559.95px)': {//xl
      textAlign:'center',
      fontSize: '3.3vh',
      color:'black',
      whiteSpace:'nowrap',
    },
  },
  graphpaper: {
    height: "29vh",
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    background:"#FFFFFF 0% 0% no-repeat padding-box",
    // boxShadow: '0px 4px 20px #0123B41A;',
    // backgroundColor: 'white',
    boxShadow:"1px 0px 8px 2px rgba(0, 0, 0, 0.14)",
    backgroundColor:"#fcfafa",
    opacity:"1",
    borderRadius: '14px',
  },
}))

const getMyValue = (input, params=[]) => {
	let test = -1;
	try{
		if (input !== undefined)
			test = input[params[0]];
		else
			return;
		if (test !== undefined){
			for(let i=1; i<params.length; i++){
				if (params[i] !== undefined)
					test = test[params[i]]
				else
					break;
			}
		}
	} catch (e) {
		console.log('My Error in getMyValue: ', e.message);
	}
	return test;
}

function GlEnergyMeter(props) {
  let myEMS='a14c6706-37fe-11ed-80b8-9829a659bba7';
  const classes = useStyles();
  const [present, setPresent] = useState('');
  const [apparent, setApparent] = useState('');
  const [active, setActive] = useState('');
  const [power, setPower] = useState('');
  const [voltage_ll, setVoltageLL] = useState('');
  const [voltage_ln, setVoltageLN] = useState('');
  const [current, setCurrent] = useState('');
  const [freq, setFreq] = useState('');
  const [energy, setEnergy] = useState({});
  const [lnphase1, setLNPhase1] = useState([]);
  const [lnphase2, setLNPhase2] = useState([]);
  const [lnphase3, setLNPhase3] = useState([]);
  const [llphase1, setLLPhase1] = useState([]);
  const [llphase2, setLLPhase2] = useState([]);
  const [llphase3, setLLPhase3] = useState([]);
  const [currentphase1, setCurrentPhase1] = useState([]);
  const [currentphase2, setCurrentPhase2] = useState([]);
  const [currentphase3, setCurrentPhase3] = useState([]);
  const [energy24hrs, setEnergy24hrs] = useState({});
  const [energy7days, setEnergy7days] = useState({});
  const [fdata, setFdata] = useState(localStorage.getItem("floorName"));
  const [floor, setFloor] = useState([]);
  const initialState1 =
  props.location.state.dev_id != null
    ? props.location.state.dev_id
    : "";
  const [deviceid, setDeviceid] = useState(initialState1);
  const initialState =
  props.location.state != null ? props.location.state.dev_name : "";
  const [data, setData] = useState(initialState);
  const [ahu1, setAhu1] = useState({});
  const [graph, setGraph] = useState([]);
  const [activeEMS, setActiveEMS] = useState(initialState1);
  const [energydevice, setEnergydevice] = useState([]);
  const zone_data = useSelector((state) => state.inDashboard.locationData);
  const buildingID = useSelector((state) => state.isLogged.data.building.id);
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });


  const handlefloorchange = (name, id) => {
    setFdata(name);
    api.floor.devicemap(id, "energyMeter")
    .then((res) => {
      // if(res.length == 0){
      //   console.log('hii em in if',res)
      //   setActiveEMS('')
      //   setEnergydevice([]);
      //   setPresent('')
      //         setApparent('')
      //         setActive('')
      //         setPower('')
      //         setVoltageLL('')
      //         setVoltageLN('')
      //         setCurrent('')
      //         setFreq('')
      // }else{
      //   console.log('hii em in else')
      //   res.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
      //   setEnergydevice(res);
      // }
      res.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
      setEnergydevice(res);
    })
    .catch((error)=>{
      console.log('hii em in catch block of devicemap in HFC')
      setEnergydevice([]);

    })
  };
  const handleChange = (name, id) => {
    setActiveEMS(id);
    api.floor.EmsData(id)
    .then(res => {
      res.map(ele => {
        if (ele.param_id === 'Prsnt_Dmd') {
          setPresent(ele.param_value)
        }
        else if (ele.param_id === 'Aprnt_Pwr_Ttl') {
          setApparent(ele.param_value)
        }
        else if (ele.param_id === 'Act_Pwr_Ttl') {
          setActive(ele.param_value)
        }
        else if (ele.param_id === 'PF_Ttl') {
          setPower(ele.param_value)
        }
        else if (ele.param_id === 'Volt_LL_Avg') {
          setVoltageLL(ele.param_value)
        }
        else if (ele.param_id === 'Volt_LN_Avg') {
          setVoltageLN(ele.param_value)
        }
        else if (ele.param_id === 'Cur_Avg') {
          setCurrent(ele.param_value)
        }
        else if (ele.param_id === 'Freq') {
          setFreq(ele.param_value)
        }
      })
    }) 
    .catch((error)=>{
      setPresent('')
      setApparent('')
      setActive('')
      setPower('')
      setVoltageLL('')
      setVoltageLN('')
      setCurrent('')
      setFreq('')
    })
    setData(name);

    api.floor.EmsGraphData(id)
    .then(res => {
      console.log('hii em in handle change and api is called',res.graphData[0]['Cur_Ph1'])
      if(res.graphData.length){
        if(res.graphData[0]['PF_Ttl']){
            setEnergy(getMyValue(res,['graphData',0,'PF_Ttl']))
        }
        if(res.graphData[0]['Volt_LN_Ph1']){
            setLNPhase1(getMyValue(res,['graphData',0,'Volt_LN_Ph1']))  
        }
        if(res.graphData[0]['Volt_LN_Ph2']){
            setLNPhase2(getMyValue(res,['graphData',0,'Volt_LN_Ph2']))
        }
        if(res.graphData[0]['Volt_LN_Ph3']){
            setLNPhase3(getMyValue(res,['graphData',0,'Volt_LN_Ph3']))  
        }
        if(res.graphData[0]['Volt_LL_Ph1']){
            setLLPhase1(getMyValue(res,['graphData',0,'Volt_LL_Ph1']))
        }
        if(res.graphData[0]['Volt_LL_Ph2']){
            setLLPhase2(getMyValue(res,['graphData',0,'Volt_LL_Ph2']))
        }
        if(res.graphData[0]['Volt_LL_Ph3']){
            setLLPhase3(getMyValue(res,['graphData',0,'Volt_LL_Ph3']))
        }
        if(res.graphData[0]['Cur_Ph1']){
          console.log('Cur_Ph1 iin handle change',getMyValue(res,['graphData',0,'Cur_Ph1']))
            setCurrentPhase1(getMyValue(res,['graphData',0,'Cur_Ph1']))
        }
        if(res.graphData[0]['Cur_Ph2']){
            setCurrentPhase2(getMyValue(res,['graphData',0,'Cur_Ph2']))
        }
        if(res.graphData[0]['Cur_Ph3']){
            setCurrentPhase3(getMyValue(res,['graphData',0,'Cur_Ph3'])) 
        }
      }
    }).catch((error)=>{
      console.log('hii em in handle change emsgraphdata api catch block')
        setEnergy([])
        setLNPhase1([])
        setLNPhase2([])
        setLNPhase3([])
        setLLPhase1([])
        setLLPhase2([])
        setLLPhase3([])
        setCurrentPhase1([])
        setCurrentPhase2([])
        setCurrentPhase3([])
    })


    api.floor.EmsGraph24hrs(id, format(new Date(),'yyyy-MM-dd'))
      .then(res => {
        if(res.graphData.length){
          setEnergy24hrs(res.graphData[0])
        }
      }).catch((error)=>{
        setEnergy24hrs({})
    })

      api.floor.EmsGraph7days(id, format(new Date(),'yyyy-MM-dd'))
      .then(res => {
        if(res.graphData.length){
          setEnergy7days(res.graphData[0])
        }
      }).catch((error)=>{
        setEnergy7days({})
    })
  };

  useEffect(() => {
    let zone_id='',z_data=[]
    zone_data.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
    // zone_data.filter((_each)=>_each.zone_type==='GL_LOCATION_TYPE_FLOOR')
    // if(fdata!== null){
      zone_data.filter((_each,i) =>{
        if(_each.zone_type==='GL_LOCATION_TYPE_FLOOR'){
          z_data.push(_each);
        }
      })
      zone_id=z_data[0].uuid
      console.log("zzzzzzzzzzzzzone_idz",zone_id)
      setFdata(z_data[0].name)
    // } else {
    //   zone_id=zone_data[0].uuid
    //   setFdata(zone_data[0].name)
    // }

    api.floor.devicemap(zone_id, "energyMeter")
      .then((res) => {
        res.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
            setEnergydevice(res);
          if(deviceid=='' && data==''){
            res.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
            setData(res[0].name)   
        // setActiveEMS(res[0].ssid);

            api.floor.EmsData(res[0].ssid)
            .then(res => {
              res.map(ele => {
                if (ele.param_id === 'Prsnt_Dmd') {
                  setPresent(ele.param_value)
                }
                else if (ele.param_id === 'Aprnt_Pwr_Ttl') {
                  setApparent(ele.param_value)
                }
                else if (ele.param_id === 'Act_Pwr_Ttl') {
                  setActive(ele.param_value)
                }
                else if (ele.param_id === 'PF_Ttl') {
                  setPower(ele.param_value)
                }
                else if (ele.param_id === 'Volt_LL_Avg') {
                  setVoltageLL(ele.param_value)
                }
                else if (ele.param_id === 'Volt_LN_Avg') {
                  setVoltageLN(ele.param_value)
                }
                else if (ele.param_id === 'Cur_Avg') {
                  setCurrent(ele.param_value)
                }
                else if (ele.param_id === 'Freq') {
                  setFreq(ele.param_value)
                }
              })
            }).catch((error)=>{
              setPresent('')
              setApparent('')
              setActive('')
              setPower('')
              setVoltageLL('')
              setVoltageLN('')
              setCurrent('')
              setFreq('')
            })
            api.floor.EmsGraphData(res[0].ssid)
            .then(res => {
              if(res.graphData.length){
                if(res.graphData[0]['PF_Ttl']){
                    setEnergy(getMyValue(res,['graphData',0,'PF_Ttl']))
                }
                if(res.graphData[0]['Volt_LN_Ph1']){
                    setLNPhase1(getMyValue(res,['graphData',0,'Volt_LN_Ph1']))  
                }
                if(res.graphData[0]['Volt_LN_Ph2']){
                    setLNPhase2(getMyValue(res,['graphData',0,'Volt_LN_Ph2']))
                }
                if(res.graphData[0]['Volt_LN_Ph3']){
                    setLNPhase3(getMyValue(res,['graphData',0,'Volt_LN_Ph3']))  
                }
                if(res.graphData[0]['Volt_LL_Ph1']){
                    setLLPhase1(getMyValue(res,['graphData',0,'Volt_LL_Ph1']))
                }
                if(res.graphData[0]['Volt_LL_Ph2']){
                    setLLPhase2(getMyValue(res,['graphData',0,'Volt_LL_Ph2']))
                }
                if(res.graphData[0]['Volt_LL_Ph3']){
                    setLLPhase3(getMyValue(res,['graphData',0,'Volt_LL_Ph3']))
                }
                if(res.graphData[0]['Cur_Ph1']){
                    setCurrentPhase1(getMyValue(res,['graphData',0,'Cur_Ph1']))
                }
                if(res.graphData[0]['Cur_Ph2']){
                    setCurrentPhase2(getMyValue(res,['graphData',0,'Cur_Ph2']))
                }
                if(res.graphData[0]['Cur_Ph3']){
                    setCurrentPhase3(getMyValue(res,['graphData',0,'Cur_Ph3'])) 
                }
              }
            }).catch((error)=>{
                setEnergy([])
                setLNPhase1([])
                setLNPhase2([])
                setLNPhase3([])
                setLLPhase1([])
                setLLPhase2([])
                setLLPhase3([])
                setCurrentPhase1([])
                setCurrentPhase2([])
                setCurrentPhase3([])
            })
        
            api.floor.EmsGraph24hrs(res[0].ssid, format(new Date(),'yyyy-MM-dd')).then(res => {
              if(res.graphData.length){
                setEnergy24hrs(res.graphData[0])
              }
            }).catch((error)=>{
                setEnergy24hrs({})
        
            })
        
            api.floor.EmsGraph7days(res[0].ssid,format(new Date(),'yyyy-MM-dd')).then(res => {
              if(res.graphData.length){
                setEnergy7days(res.graphData[0])
              }
              
            }).catch((error)=>{
                setEnergy7days({})
            })
          }
        else{
            
            api.floor.EmsData(activeEMS)
            .then(res => {
              res.map(ele => {
                if (ele.param_id === 'Prsnt_Dmd') {
                  setPresent(ele.param_value)
                }
                else if (ele.param_id === 'Aprnt_Pwr_Ttl') {
                  setApparent(ele.param_value)
                }
                else if (ele.param_id === 'Act_Pwr_Ttl') {
                  setActive(ele.param_value)
                }
                else if (ele.param_id === 'PF_Ttl') {
                  setPower(ele.param_value)
                }
                else if (ele.param_id === 'Volt_LL_Avg') {
                  setVoltageLL(ele.param_value)
                }
                else if (ele.param_id === 'Volt_LN_Avg') {
                  setVoltageLN(ele.param_value)
                }
                else if (ele.param_id === 'Cur_Avg') {
                  setCurrent(ele.param_value)
                }
                else if (ele.param_id === 'Freq') {
                  setFreq(ele.param_value)
                }
              })
            })
            .catch((error)=>{
              setPresent('')
              setApparent('')
              setActive('')
              setPower('')
              setVoltageLL('')
              setVoltageLN('')
              setCurrent('')
              setFreq('')
            })
            api.floor.EmsGraphData(activeEMS)
            .then(res => {
              if(res.graphData.length){
                if(res.graphData[0]['PF_Ttl']){
                    setEnergy(getMyValue(res,['graphData',0,'PF_Ttl']))
                }
                if(res.graphData[0]['Volt_LN_Ph1']){
                    setLNPhase1(getMyValue(res,['graphData',0,'Volt_LN_Ph1']))  
                }
                if(res.graphData[0]['Volt_LN_Ph2']){
                    setLNPhase2(getMyValue(res,['graphData',0,'Volt_LN_Ph2']))
                }
                if(res.graphData[0]['Volt_LN_Ph3']){
                    setLNPhase3(getMyValue(res,['graphData',0,'Volt_LN_Ph3']))  
                }
                if(res.graphData[0]['Volt_LL_Ph1']){
                    setLLPhase1(getMyValue(res,['graphData',0,'Volt_LL_Ph1']))
                }
                if(res.graphData[0]['Volt_LL_Ph2']){
                    setLLPhase2(getMyValue(res,['graphData',0,'Volt_LL_Ph2']))
                }
                if(res.graphData[0]['Volt_LL_Ph3']){
                    setLLPhase3(getMyValue(res,['graphData',0,'Volt_LL_Ph3']))
                }
                if(res.graphData[0]['Cur_Ph1']){
                    setCurrentPhase1(getMyValue(res,['graphData',0,'Cur_Ph1']))
                }
                if(res.graphData[0]['Cur_Ph2']){
                    setCurrentPhase2(getMyValue(res,['graphData',0,'Cur_Ph2']))
                }
                if(res.graphData[0]['Cur_Ph3']){
            setCurrentPhase3(getMyValue(res,['graphData',0,'Cur_Ph3'])) 
                }
              }
            })
            .catch((error)=>{
                setEnergy([])
                setLNPhase1([])
                setLNPhase2([])
                setLNPhase3([])
                setLLPhase1([])
                setLLPhase2([])
                setLLPhase3([])
                setCurrentPhase1([])
                setCurrentPhase2([])
                setCurrentPhase3([])
            })
        
            api.floor.EmsGraph24hrs(activeEMS, format(new Date(),'yyyy-MM-dd')).then(res => {
              if(res.graphData.length){
                setEnergy24hrs(res.graphData[0])
              }
            }).catch((error)=>{
                setEnergy24hrs({})
        
            })
        
            api.floor.EmsGraph7days(activeEMS,format(new Date(),'yyyy-MM-dd')).then(res => {
              if(res.graphData.length){
                setEnergy7days(res.graphData[0])
              }
              
            }).catch((error)=>{
                setEnergy7days({})
            })
        } 
      })
      .catch((error)=>{ 
        setEnergydevice([]);
        setData('')   
        setActiveEMS('');

    })
   

    api.dashboard.getMetricData(buildingID)
    .then((res) => {
      res.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
      // console.log("reeeeesss",res)
      setFloor(res);
    })
    .catch((error)=>{
      setFloor([]);
    });


    const timer = setInterval(() => {
      console.log("em inside time interval -->",activeEMS)
        api.floor.EmsData(activeEMS)
        .then(res => {
          res.map(ele => {
            if (ele.param_id === 'Prsnt_Dmd') {
              setPresent(ele.param_value)
            }
            else if (ele.param_id === 'Aprnt_Pwr_Ttl') {
              setApparent(ele.param_value)
            }
            else if (ele.param_id === 'Act_Pwr_Ttl') {
              setActive(ele.param_value)
            }
            else if (ele.param_id === 'PF_Ttl') {
              setPower(ele.param_value)
            }
            else if (ele.param_id === 'Volt_LL_Avg') {
              setVoltageLL(ele.param_value)
            }
            else if (ele.param_id === 'Volt_LN_Avg') {
              setVoltageLN(ele.param_value)
            }
            else if (ele.param_id === 'Cur_Avg') {
              setCurrent(ele.param_value)
            }
            else if (ele.param_id === 'Freq') {
              setFreq(ele.param_value)
            }
          })
        })
        .catch((error)=>{
          setPresent('')
          setApparent('')
          setActive('')
          setPower('')
          setVoltageLL('')
          setVoltageLN('')
          setCurrent('')
          setFreq('')
        })
      api.floor.EmsGraphData(activeEMS).then(res => {
        if(res.graphData.length){
        if(res.graphData[0]['PF_Ttl']){
            setEnergy(getMyValue(res,['graphData',0,'PF_Ttl']))
        }
        if(res.graphData[0]['Volt_LN_Ph1']){
            setLNPhase1(getMyValue(res,['graphData',0,'Volt_LN_Ph1']))  
        }
        if(res.graphData[0]['Volt_LN_Ph2']){
            setLNPhase2(getMyValue(res,['graphData',0,'Volt_LN_Ph2']))
        }
        if(res.graphData[0]['Volt_LN_Ph3']){
            setLNPhase3(getMyValue(res,['graphData',0,'Volt_LN_Ph3']))  
        }
        if(res.graphData[0]['Volt_LL_Ph1']){
            setLLPhase1(getMyValue(res,['graphData',0,'Volt_LL_Ph1']))
        }
        if(res.graphData[0]['Volt_LL_Ph2']){
            setLLPhase2(getMyValue(res,['graphData',0,'Volt_LL_Ph2']))
        }
        if(res.graphData[0]['Volt_LL_Ph3']){
            setLLPhase3(getMyValue(res,['graphData',0,'Volt_LL_Ph3']))
        }
        if(res.graphData[0]['Cur_Ph1']){
            setCurrentPhase1(getMyValue(res,['graphData',0,'Cur_Ph1']))
        }
        if(res.graphData[0]['Cur_Ph2']){
            setCurrentPhase2(getMyValue(res,['graphData',0,'Cur_Ph2']))
        }
        if(res.graphData[0]['Cur_Ph3']){
            setCurrentPhase3(getMyValue(res,['graphData',0,'Cur_Ph3'])) 
        }
        }
      }).catch((error)=>{
        setEnergy([])
        setLNPhase1([])
        setLNPhase2([])
        setLNPhase3([])
        setLLPhase1([])
        setLLPhase2([])
        setLLPhase3([])
        setCurrentPhase1([])
        setCurrentPhase2([])
        setCurrentPhase3([])
    })

      api.floor.EmsGraph24hrs(activeEMS, format(new Date(),'yyyy-MM-dd')).then(res => {
        if(res.graphData.length){
          setEnergy24hrs(res.graphData[0])
        }
      }).catch((error)=>{
        setEnergy24hrs({})
    })

      api.floor.EmsGraph7days(activeEMS, format(new Date(),'yyyy-MM-dd')).then(res => {
        if(res.graphData.length){
          setEnergy7days(res.graphData[0])
        }
      }).catch((error)=>{
        setEnergy7days({})
    })
    }, 5000);
    return () => {
      clearInterval(timer); 
    }
  }, [activeEMS]);

  return (
    <div className={classes.root} style={{ marginTop: "0%" }}>
      <Grid container xs={12} spacing={2}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5} xxl={5}>
                <FormControl
                                variant="filled"
                                size="large"
                                className={classes.formControl}
                                style={{
                                    width: "max-content",
                                    minWidth: "100%",
                                    backgroundColor: "#eeeef5",
                                    fontFamily: "Arial"
                                }}
                                >
                                <Select
                                    labelId="filled-hidden-label-small"
                                    id="demo-simple-select-outlined"
                                    label="Floor"
                                    className={classes.select}
                                    style={{
                                    fontWeight: "bold",
                                    height: "6vh",
                                    borderRadius: '0.8vw',
                                    fontFamily: "Arial"
                                    }}
                                    value={fdata || ''}
                                    disableUnderline
                                >
                                  {floor.map((_item) => (
                                    <MenuItem
                                      key={_item.id}
                                      value={_item.name}
                                      onClick={() => handlefloorchange(_item.name, _item.id)}
                                    >
                                      {_item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <FormControl
                                variant="filled"
                                size="large"
                                className={classes.formControl}
                                style={{
                                    width: "max-content",
                                    minWidth: "100%",
                                    backgroundColor: "#eeeef5",
                                    fontFamily: "Arial"
                                }}
                                >
                                <Select
                                    labelId="filled-hidden-label-small"
                                    id="demo-simple-select-outlined"
                                    label="Floor"
                                    className={classes.select}
                                    style={{
                                    fontWeight: "bold",
                                    height: "6vh",
                                    borderRadius: '0.8vw',
                                    fontFamily: "Arial"
                                    }}
                                    value={data}
                                    disableUnderline
                                >
                                  {energydevice.map((_item) => (
                                    <MenuItem
                                      key={_item.ssid}
                                      value={_item.name}
                                      onClick={() => handleChange(_item.name, _item.ssid)}
                                    >
                                      {_item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Card className={`${classes.paper}`}>
                <Grid container item xs={12} spacing={1}>
                      <Grid item xs={6} sm={4} md lg xl xxl>
                        <Card style={{  boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa", "border-radius": "10px", height: "12vh" }}>
                          <Grid container item xs={12} direction='column' style={{"justify-content": "center","align-content": "center", whiteSpace: "nowrap"}} >
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center'>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className={classes.headingFont} style={{display: 'flex', justifyContent: 'center'}}>Kilo Watt</Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} className={classes.statusFont}  style={{display: 'flex', justifyContent: 'center'}}><Success>{formatter.format(present)} </Success></Grid>
                                        </Grid>       
                          </Grid>
                      </Card>
                      </Grid>
                      <Grid item xs={6} sm={4} md lg xl xxl>
                        <Card style={{  boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa", "border-radius": "10px", height: "12vh" }}>
                          <Grid container item xs={12} direction='column' style={{justifyContent: "center",alignContent: "center", whiteSpace: "nowrap"}} >
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center'>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}  className={classes.headingFont} style={{display: 'flex', justifyContent: 'center'}}>Apparent Power</Grid>
                                            <Grid container item xs={12}>
                                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.statusFont} style={{display: 'flex', justifyContent: 'center'}}>
                                                <Success>{formatter.format(apparent)}VA</Success>
                                              </Grid>
                                            </Grid>
                                        </Grid>       
                                      </Grid>
                      </Card>
                      </Grid>
                      <Grid item xs={6} sm={4} md lg xl xxl>
                        <Card style={{  boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa", "border-radius": "10px", height: "12vh" }}>
                          <Grid container item xs={12} direction='column' style={{"justify-content": "center","align-content": "center", whiteSpace: "nowrap"}} >
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.headingFont}>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{display: 'flex', justifyContent: 'center'}}>Active Power</Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                              <Grid container item xs={12}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.statusFont} style={{display: 'flex', justifyContent: 'center'}}>
                                                  <Success>{formatter.format(active)}W</Success>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                        </Grid>       
                          </Grid>
                      </Card>
                      </Grid>
                      <Grid item xs={6} sm={4} md lg xl xxl>
                        <Card style={{  boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa", "border-radius": "10px", height: "12vh" }}>
                          <Grid container item xs={12} direction='column' style={{"justify-content": "center","align-content": "center", whiteSpace: "nowrap"}} >
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.headingFont}>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{display: 'flex', justifyContent: 'center'}}>Average PF</Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} className={classes.statusFont}><Success>{formatter.format(power)}</Success></Grid>
                                    </Grid>       
                          </Grid>
                      </Card>
                      </Grid>
                      <Grid item xs={6} sm={4} md lg xl xxl>
                        <Card style={{  boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa", "border-radius": "10px", height: "12vh" }}>
                          <Grid container item xs={12} direction='column' style={{"justify-content": "center","align-content": "center", whiteSpace: "nowrap"}} >
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.headingFont}>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{display: 'flex', justifyContent: 'center'}}>L-L Voltage[Avg]</Grid>
                                            <Grid container item xs={12}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.statusFont} style={{display: 'flex', justifyContent: 'center'}}>
                                            <Success>{formatter.format(voltage_ll)}V</Success>
                                            {/* <Grid item xs={12}>
                                              <Grid container item xs={12}>
                                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={classes.statusFont}>
                                                  <Success>{formatter.format(voltage_ll)}</Success>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}> </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ marginTop: "1.5vh",fontSize:"2vh",color:"forestgreen"}}>
                                                  <>V</>
                                                </Grid>
                                              </Grid>
                                          </Grid> */}
                                            </Grid>
                                            </Grid>
                                        </Grid>       
                          </Grid>
                      </Card>
                      </Grid>
                      <Grid item xs={6} sm={4} md lg xl xxl>
                        <Card style={{  boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa", "border-radius": "10px", height: "12vh" }}>
                          <Grid container item xs={12} direction='column' style={{"justify-content": "center","align-content": "center", whiteSpace: "nowrap"}} >
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.headingFont}>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{display: 'flex', justifyContent: 'center'}}>L-N Voltage[Avg]</Grid>
                                            <Grid container item xs={12}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.statusFont} style={{display: 'flex', justifyContent: 'center'}}>
                                            <Success>{formatter.format(voltage_ln)}V</Success>
                                            {/* <Grid item xs={12}>
                                              <Grid container item xs={12}>
                                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={classes.statusFont}>
                                                  <Success>{formatter.format(voltage_ln)}</Success>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}> </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ marginTop: "1.5vh",fontSize:"2vh",color:"forestgreen"}}>
                                                  <>V</>
                                                </Grid>
                                              </Grid>
                                          </Grid> */}
                                            </Grid>
                                            </Grid>
                                          </Grid>       
                          </Grid>
                      </Card>
                      </Grid>
                      <Grid item xs={6} sm={4} md lg xl xxl>
                        <Card style={{  boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa", "border-radius": "10px", height: "12vh" }}>
                          <Grid container item xs={12} direction='column' style={{"justify-content": "center","align-content": "center", whiteSpace: "nowrap"}} >
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.headingFont}>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{display: 'flex', justifyContent: 'center'}}>Current</Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.statusFont} style={{display: 'flex', justifyContent: 'center'}}>
                                              <Success>{formatter.format(current)}mA</Success>
                                            {/* <Grid item xs={12}>
                                              <Grid container item xs={12}>
                                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={classes.statusFont}>
                                                  <Success>{formatter.format(current)}</Success>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}> </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ marginTop: "1.5vh",fontSize:"2vh",color:"forestgreen"}}>
                                                  <>mA</>
                                                </Grid>
                                              </Grid>
                                          </Grid> */}
                                        </Grid>
                                       </Grid>       
                          </Grid>
                      </Card>
                      </Grid>
                      <Grid item xs={6} sm={4} md lg xl xxl>
                        <Card style={{  boxShadow: "inset 0px 0px 0px 2px rgb(76 175 80)",backgroundColor:"#fcfafa", "border-radius": "10px", height: "12vh" }}>
                          <Grid container item xs={12} direction='column' style={{"justify-content": "center","align-content": "center", whiteSpace: "nowrap"}} >
                                        <Grid container item xs={12} spacing={1} direction='column' alignItems='center' className={classes.headingFont}>                                     
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}> </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} style={{display: 'flex', justifyContent: 'center'}}>Frequency</Grid>
                                            <Grid container item xs={12}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={classes.statusFont} style={{display: 'flex', justifyContent: 'center'}}>
                                            <Success>{formatter.format(freq)}Hz</Success>
                                            {/* <Grid item xs={12}>
                                              <Grid container item xs={12}>
                                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={classes.statusFont}>
                                                  <Success>{formatter.format(freq)}</Success>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}> </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ marginTop: "1.5vh",fontSize:"2vh",color:"forestgreen"}}>
                                                  <>Hz</>
                                                </Grid>
                                              </Grid>
                                          </Grid> */}
                                            </Grid>
                                            </Grid>
                                        </Grid>       
                          </Grid>
                      </Card>
                      </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
      </Grid>
      <Grid container xs={12} spacing={2} style={{marginTop:'0.5vh'}}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Card className={classes.graphpaper}>
                    <Grid container item xs={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>L-L Voltage</b></Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <TimeSeries1 data={llphase1} data2={llphase2} data3={llphase3} name='L-L Voltage[V]' />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Card className={classes.graphpaper}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>Current</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <TimeSeries1 data={currentphase1} data2={currentphase2} data3={currentphase3} name='Current[A]'/>    
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Card className={classes.graphpaper}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>24 Hour Consumption</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    {Object.keys(energy24hrs).length==0 ? <>
                        <h4 style={{marginTop:"44px",marginLeft:"5px"}}>No data available</h4>
                        </>
                        :
                        <>
                          {Object.keys(energy24hrs).map((key) => (
                            <Devicetrend data={energy24hrs[key]} param={key} />
                      ))}
                        </>}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Card className={classes.graphpaper}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>L-N Voltage</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <TimeSeries1 data={lnphase1} data2={lnphase2} data3={lnphase3} name='L-N Voltage[V]'/>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Card className={classes.graphpaper}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>Power Factor</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    {Object.keys(energy).length==0 ? <>
                        {/* <h4 style={{marginTop:"62px",marginLeft:"80px"}}>No data available</h4> */}
                        <h4 style={{marginTop:"44px",marginLeft:"5px"}}>No data available</h4>
                        </>
                        :
                        <>    
                          <TimeSeries data={energy} param="PF_Ttl" />
                        </>}
                    </Grid>
                  </Grid>
                </Card> 
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                <Card className={classes.graphpaper}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ fontSize: "15px", color: "black", whiteSpace: "nowrap" }}><b>7 Days Consumption</b></Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    {Object.keys(energy7days).length==0 ? <>
                        <h4 style={{marginTop:"44px",marginLeft:"5px"}}>No data available</h4>
                        </>
                        :
                        <>
                          {Object.keys(energy7days).map((key) => (
                        <Devicetrend data={energy7days[key]} param={key} />
                      ))}
                        </>}

                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default GlEnergyMeter

