import React, { useEffect } from 'react';
// import the core library.
import ReactEcharts from "echarts-for-react";
import api from '../../api';
import { useCenteredTree } from "../../helper";
// then import echarts modules those you have used manually.
import 'echarts/lib/chart/tree';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title'



function NetworkDiagram() {
  const [translate, containerRef] = useCenteredTree();
  const [orgChart, setOrgChart] = React.useState([]);

  const getData = () => {
    fetch('/material-dashboard-react/data.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setOrgChart({ ...orgChart, name: myJson.networkStatus.name, children: myJson.networkStatus.children });
      });
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'cloud') {
      console.log("process", process.env.REACT_APP_ENVIRONMENT)
      getData()
    } else {
      api.networkStatus.getNetworkStatus().then(res => {
        setOrgChart([{ ...orgChart, name: res.results.name, children: res.results.children }]);
      })
    }
  }, [])

  // const getTooltipData = (params) => {
  //   console.log("params", params.value);
  //   const additionalText = " - Additional info here";
  //   return `${params.name}${params.value}`;
  // };


  return (
    <ReactEcharts
      option={{
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          formatter: (params)=>{
            const value = params.data.value ? params.data.value : '';
            return `${params.name}: ${value}`} 
        },
        series: [
          {
            orientation: "horizontal",
            pathFunc: "elbow",
            type: 'tree',
            data: orgChart,
            translate: { translate },
            top: '1%',
            left: '7%',
            bottom: '1%',
            right: '20%',
            symbolSize: 7,
            lineStyle: { color: 'black' },
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 15
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            emphasis: {
              focus: 'descendant'
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      }}

      style={{ height: '500px', width: '100%' }}
    />
  )
}
export default NetworkDiagram